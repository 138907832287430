import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Table from './Table'
import ReassignIntercomModal from '../ReassignIntercomModal'

import { fetchCompanyDevices } from 'store/operations'

// const fetchCompanyDevices = () => ({ type: 'kek' })

class TableContainer extends Component {
  state = {
    order: 'asc',
    orderBy: 'device',
    limit: 5,
    rowCount: 5,
    page: 0,
    rowsPerPage: 10,
    selected: [],
    reassignIntercomModal: false,
    searchQuery: '',
    lastSelectedCompanyUuid: this.props.selectedCompanyUuid,
  }

  // componentDidMount() {
  // const { searchQuery } = this.props
  // const { page, rowsPerPage: items } = this.state
  // this.props.dispatch(fetchCompanyDevices({ page, items, searchQuery }))
  // }

  static getDerivedStateFromProps(props, state) {
    // clear selected when company gets changed
    if (props.selectedCompanyUuid) {
      if (props.selectedCompanyUuid !== state.lastSelectedCompanyUuid) {
        return {
          selected: [],
          lastSelectedCompanyUuid: props.selectedCompanyUuid,
        }
      }
    }

    return state
  }

  onSearch = e => {
    this.setState({ searchQuery: e.target.value }, () =>
      this.props.dispatch(
        fetchCompanyDevices({
          page: this.state.page,
          items: this.state.rowsPerPage,
          companyUuid: this.props.selectedCompanyUuid,
          q: this.state.searchQuery,
        }),
      ),
    )
  }

  handleSelect = (e: SyntheticEvent<>, uuid: string, isSelected: boolean) => {
    const { selected } = this.state

    this.setState({
      selected: isSelected
        ? selected.filter(i => i !== uuid)
        : [...selected, uuid],
    })
  }

  handleSelectAll = e => {
    if (e.target.checked) {
      return this.setState({
        selected: this.props.devices.map(d => d.duuid),
      })
    }

    this.setState({
      selected: [],
    })
  }

  handleSortRequest = (event, property) => {
    const orderBy = property
    let order = 'desc'
    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc'
    }
    this.setState({ order, orderBy })
  }

  getSorting = (order, orderBy) => {
    return order === 'desc'
      ? (a: Object, b: Object) => (b[orderBy] < a[orderBy] ? -1 : 1)
      : (a: Object, b: Object) => (a[orderBy] < b[orderBy] ? -1 : 1)
  }

  handleChangePage = (event: ?SyntheticEvent<>, page: number) => {
    this.setState({ page, devices: null }, () => {
      this.props.dispatch(
        fetchCompanyDevices({
          page: this.state.page,
          items: this.state.rowsPerPage,
          companyUuid: this.props.selectedCompanyUuid,
        }),
      )
    })
  }

  openReassignIntercomModal = () =>
    this.setState({ reassignIntercomModal: true })

  handleChangeRowsPerPage = (e: SyntheticEvent<>) => {
    //$FlowFixMe
    this.setState({ rowsPerPage: e.target.value }, () =>
      this.props.dispatch(
        fetchCompanyDevices({
          page: this.state.page,
          items: this.state.rowsPerPage,
          companyUuid: this.props.selectedCompanyUuid,
        }),
      ),
    )
  }

  unselectAll = () => this.setState({ selected: [] })

  extractProps = () => ({
    ...this.state,
    ...this.props,
    handleSortRequest: this.handleSortRequest,
    getSorting: this.getSorting,
    handleChangePage: this.handleChangePage,
    handleChangeRowsPerPage: this.handleChangeRowsPerPage,
    handleSelect: this.handleSelect,
    handleSelectAll: this.handleSelectAll,
    openReassignIntercomModal: this.openReassignIntercomModal,
    onSearch: this.onSearch,
  })

  render() {
    return (
      <>
        <Table {...this.extractProps()} />
        <ReassignIntercomModal
          open={this.state.reassignIntercomModal}
          handleClose={() =>
            this.setState({
              reassignIntercomModal: false,
            })
          }
          devices={this.state.selected}
          unselectAll={this.unselectAll}
        />
      </>
    )
  }
}

const mapStateToProps = ({ companies: { devices, selected, totalPages } }) => ({
  devices: devices,
  totalPages: totalPages,
  selectedCompanyUuid: selected && selected.value,
})

const mapDispatchToProps = dispatch => ({
  dispatch,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(TableContainer))
