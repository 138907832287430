import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import API from 'utils/api'
import { buildUrl, clamp } from 'utils/helpers'
import config from 'config'
import { clearDevices } from './devicesSlice'

export const fetchServicePartners = createAsyncThunk(
  'devices/fetchServicePartners',
  async (params, { getState, rejectWithValue, dispatch }) => {
    try {
      const payload = await API.request(buildUrl(`/service_partners`, params), {
        dispatch,
      })

      return payload
    } catch {
      return rejectWithValue()
    }
  },
)

const placeholder = {
  value: undefined,
  label: 'По обслуживающей компании',
}

// reducers
export const servicePartnersSlice = createSlice({
  name: 'servicePartners',
  initialState: {
    list: null,
    page: 1,
    limit: 100,
    search: '',
    filter: placeholder,
  },
  reducers: {
    [clearDevices.type]: (state) => {
      state.list = []
      state.filter = placeholder
    },
    incPage: (state) => {
      state.page = clamp(state.page + 1, 1, state.pages)
    },
    decPage: (state) => {
      state.page = clamp(state.page - 1, 1, state.pages)
    },
    setSearch: (state, { payload }) => {
      state.search = payload
    },

    setServicePartnerFilter: (state, { payload }) => {
      state.filter = payload
    },
  },
  extraReducers: {
    [fetchServicePartners.fulfilled]: (state, { payload }) => {
      state.list = [
        placeholder,
        ...payload.map((s) => ({
          label: s.service_partner_name,
          value: s.service_partner_uuid,
        })),
      ]
    },
  },
})

export const {
  incPage,
  decPage,
  setServicePartnerFilter,
} = servicePartnersSlice.actions

export default servicePartnersSlice.reducer
