const pad = function (num, totalChars) {
  var pad = '0'
  num = num + ''
  while (num.length < totalChars) {
    num = pad + num
  }
  return num
}

const changeColor = function (color, ratio, darker) {
  if (!color) return

  // Trim trailing/leading whitespace
  color = color.replace(/^\s*|\s*$/, '')

  // Expand three-digit hex
  color = color.replace(/^#?([a-f0-9])([a-f0-9])([a-f0-9])$/i, '#$1$1$2$2$3$3')

  // Calculate ratio
  var difference = Math.round(ratio * 256) * (darker ? -1 : 1),
    // Determine if input is RGB(A)
    rgb = color.match(
      new RegExp(
        '^rgba?\\(\\s*' +
          '(\\d|[1-9]\\d|1\\d{2}|2[0-4][0-9]|25[0-5])' +
          '\\s*,\\s*' +
          '(\\d|[1-9]\\d|1\\d{2}|2[0-4][0-9]|25[0-5])' +
          '\\s*,\\s*' +
          '(\\d|[1-9]\\d|1\\d{2}|2[0-4][0-9]|25[0-5])' +
          '(?:\\s*,\\s*' +
          '(0|1|0?\\.\\d+))?' +
          '\\s*\\)$',
        'i'
      )
    ),
    alpha = !!rgb && rgb[4] != null ? rgb[4] : null,
    // Convert hex to decimal
    decimal = !!rgb
      ? [rgb[1], rgb[2], rgb[3]]
      : color
          .replace(
            /^#?([a-f0-9][a-f0-9])([a-f0-9][a-f0-9])([a-f0-9][a-f0-9])/i,
            function () {
              return (
                parseInt(arguments[1], 16) +
                ',' +
                parseInt(arguments[2], 16) +
                ',' +
                parseInt(arguments[3], 16)
              )
            }
          )
          .split(/,/),
    // eslint-disable-next-line
    returnValue

  // Return RGB(A)
  return !!rgb
    ? 'rgb' +
        (alpha !== null ? 'a' : '') +
        '(' +
        Math[darker ? 'max' : 'min'](
          parseInt(decimal[0], 10) + difference,
          darker ? 0 : 255
        ) +
        ', ' +
        Math[darker ? 'max' : 'min'](
          parseInt(decimal[1], 10) + difference,
          darker ? 0 : 255
        ) +
        ', ' +
        Math[darker ? 'max' : 'min'](
          parseInt(decimal[2], 10) + difference,
          darker ? 0 : 255
        ) +
        (alpha !== null ? ', ' + alpha : '') +
        ')'
    : // Return hex
      [
        '#',
        pad(
          Math[darker ? 'max' : 'min'](
            parseInt(decimal[0], 10) + difference,
            darker ? 0 : 255
          ).toString(16),
          2
        ),
        pad(
          Math[darker ? 'max' : 'min'](
            parseInt(decimal[1], 10) + difference,
            darker ? 0 : 255
          ).toString(16),
          2
        ),
        pad(
          Math[darker ? 'max' : 'min'](
            parseInt(decimal[2], 10) + difference,
            darker ? 0 : 255
          ).toString(16),
          2
        ),
      ].join('')
}

export const lighterColor = function (color, ratio) {
  return changeColor(color, ratio, false)
}
export const darkerColor = function (color, ratio) {
  return changeColor(color, ratio, true)
}

function getYesterday(d) {
  return new Date(d.setDate(d.getDate() - 1))
}

export const formatDate = (date, fullDate = false, noTime) => {
  const dateOptions = fullDate
    ? {
        month: 'long',
        day: 'numeric',
        hour: noTime ? undefined : 'numeric',
        minute: noTime ? undefined : 'numeric',
        year: 'numeric',
      }
    : {
        month: '2-digit',
        day: 'numeric',
        hour: noTime ? undefined : 'numeric',
        minute: noTime ? undefined : 'numeric',
        year: '2-digit',
      }
  const dateObj = new Date(date)
  const isToday = new Date().toDateString() === dateObj.toDateString()
  const isYesterday =
    getYesterday(new Date()).toDateString() === dateObj.toDateString()

  return isToday
    ? `сегодня, ${dateObj.toLocaleTimeString(undefined, {
        hour: 'numeric',
        minute: 'numeric',
      })}`
    : isYesterday
    ? `вчера, ${dateObj.toLocaleTimeString(undefined, {
        hour: 'numeric',
        minute: 'numeric',
      })}`
    : dateObj.toLocaleString(undefined, dateOptions)
}

// url

export const buildUrlQuery = (params) =>
  Object.entries(params)
    .filter(([key, val]) => val !== undefined)
    .map((pair) => pair.map(encodeURIComponent).join('='))
    .join('&')

export const buildUrl = (baseUrl, params = {}) => {
  const query = buildUrlQuery(params)
  return query ? baseUrl + '?' + query : baseUrl
}

export function clamp(num, min, max) {
  return Math.min(Math.max(num, min), max)
}

export const copyToClipboard = (str) => {
  const { clipboard } = window.navigator
  /*
   * fallback to older browsers (including Safari)
   * if clipboard API not supported
   */
  if (!clipboard || typeof clipboard.writeText !== `function`) {
    const textarea = document.createElement(`textarea`)
    textarea.value = str
    textarea.setAttribute(`readonly`, true)
    textarea.setAttribute(`contenteditable`, true)
    textarea.style.position = `absolute`
    textarea.style.left = `-9999px`
    document.body.appendChild(textarea)
    textarea.select()
    const range = document.createRange()
    const sel = window.getSelection()
    sel.removeAllRanges()
    sel.addRange(range)
    textarea.setSelectionRange(0, textarea.value.length)
    document.execCommand(`copy`)
    document.body.removeChild(textarea)

    return Promise.resolve(true)
  }

  return clipboard.writeText(str)
}
