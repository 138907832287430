import React, { useState } from 'react'
import {
  Toolbar,
  Typography,
  Tooltip,
  IconButton,
  TextField,
} from '@material-ui/core'
import styled, { css } from 'styled-components'
import { SubdirectoryArrowRight, Search } from '@material-ui/icons'

const StyledToolbar = styled(Toolbar)`
  padding-right: 1rem;
  ${p =>
    p.numSelected > 0 &&
    css`
      background-color: ${p => p.theme.palette.other.pink};
    `}
`
const Actions = styled.div`
  display: flex;
  color: ${p => p.theme.palette.text.primary};
  align-items: center;
`

const TableToolbar = props => {
  const { selected } = props
  const numSelected = selected.length
  const [isSearching, setIsSearching] = useState(false)
  return (
    <StyledToolbar numSelected={numSelected}>
      <div style={{ flex: '0 0 auto' }}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} выбрано
          </Typography>
        ) : (
          <Typography variant="h5">Устройства</Typography>
        )}
      </div>
      <div
        style={{
          flex: '1 1 100%',
        }}
      />
      <Actions>
        {numSelected > 0 && (
          <>
            <Tooltip title="Перепривязать на другую компанию">
              <IconButton
                aria-label="Перепривязать"
                onClick={props.openReassignIntercomModal}
              >
                <SubdirectoryArrowRight />
              </IconButton>
            </Tooltip>
          </>
        )}
        {isSearching ? (
          <TextField
            placeholder="номер панели"
            onBlur={() => setIsSearching(false)}
            autoFocus
            onChange={props.onSearch}
            value={props.searchQuery}
          />
        ) : (
          <Tooltip title="Поиск">
            <IconButton aria-label="Поиск" onClick={() => setIsSearching(true)}>
              <Search />
            </IconButton>
          </Tooltip>
        )}
      </Actions>
    </StyledToolbar>
  )
}

export default TableToolbar
