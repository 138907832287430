//@flow
//$FlowFixMe
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Button, Select } from 'sputnik-ui'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Typography,
} from '@material-ui/core'

import { fetchDevices, linkDeviceToCompany } from 'store/operations'

const Transition = props => <Slide direction="up" {...props} />

const LinkDeviceModal = ({
  open,
  selectedCompanyUuid,
  handleClose,
  dispatch,
  devices,
  devicesCount,
}: Props) => {
  const [selectedDevices, setSelectedDevices] = useState([])

  const handleSubmit = e => {
    e.preventDefault()
    const devicesUuids = selectedDevices.map(d => d.value)

    dispatch(
      linkDeviceToCompany({
        company: selectedCompanyUuid,
        devices: devicesUuids,
      }),
    )
  }

  const devicesPromise = q =>
    new Promise((resolve, reject) =>
      dispatch(fetchDevices({ q }))
        .then(devices => {
          const options = devices.map(d => ({
            label: d.name,
            value: d.uuid,
            search: d.name.slice(1),
          }))
          resolve(options)
        })
        .catch(reject),
    )

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      PaperProps={{ style: styles.dialogWindow }}
      onExit={() => {}}
    >
      <DialogTitle>Привязать устройства</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent style={{ overflow: 'visible', paddingTop: 0 }}>
          <Select
            name="selectedDevices"
            handleSelect={setSelectedDevices}
            selected={selectedDevices}
            loadOptions={devicesPromise}
            placeholder="Код устройства"
            async
            defaultOptions
          />
          {devicesCount && selectedDevices && (
            <Typography style={{ marginTop: '.325rem' }} variant="caption">
              {`Осталось ${devicesCount - selectedDevices.length} устройств`}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            secondary
            rounded
            size="small"
            tabIndex={-1}
          >
            закрыть
          </Button>
          <Button
            rounded
            primary
            size="small"
            onClick={handleSubmit}
            disabled={selectedDevices && selectedDevices.length < 1}
            type="submit"
          >
            привязать устройства
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

type MS = {
  devices: ?(Object[]),
  devicesCount: ?number,
  selectedCompanyUuid: string,
}

type Props = {
  open: boolean,
  handleClose: Function,
  dispatch: Function,
} & MS

const styles = {
  dialogWindow: { overflow: 'visible', maxWidth: 768, width: '100%' },
}

const mapStateToProps = ({ companies, devices }): MS => ({
  selectedCompanyUuid: companies.selected && companies.selected.value,
  devices: devices.list,
  devicesCount: devices.count,
})

export default connect(mapStateToProps)(LinkDeviceModal)
