export const FETCH_COMPANIES_REQUEST = 'companies/FETCH_COMPANIES_REQUEST'
export const FETCH_COMPANIES_SUCCESS = 'companies/FETCH_COMPANIES_SUCCESS'
export const FETCH_COMPANIES_FAILURE = 'companies/FETCH_COMPANIES_FAILURE'

export const CREATE_COMPANY_REQUEST = 'companies/CREATE_COMPANY_REQUEST'
export const CREATE_COMPANY_SUCCESS = 'companies/CREATE_COMPANY_SUCCESS'
export const CREATE_COMPANY_FAILURE = 'companies/CREATE_COMPANY_FAILURE'

export const LINK_DEVICE_TO_COMPANY_REQUEST = 'companies/LINK_DEVICE_TO_COMPANY_REQUEST'
export const LINK_DEVICE_TO_COMPANY_SUCCESS = 'companies/LINK_DEVICE_TO_COMPANY_SUCCESS'
export const LINK_DEVICE_TO_COMPANY_FAILURE = 'companies/LINK_DEVICE_TO_COMPANY_FAILURE'

export const FETCH_INVOICES_REQUEST = 'companies/FETCH_INVOICES_REQUEST'
export const FETCH_INVOICES_SUCCESS = 'companies/FETCH_INVOICES_SUCCESS'
export const FETCH_INVOICES_FAILURE = 'companies/FETCH_INVOICES_FAILURE'

export const FETCH_COMPANY_DEVICES_REQUEST = 'companies/FETCH_COMPANY_DEVICES_REQUEST'
export const FETCH_COMPANY_DEVICES_SUCCESS = 'companies/FETCH_COMPANY_DEVICES_SUCCESS'
export const FETCH_COMPANY_DEVICES_FAILURE = 'companies/FETCH_COMPANY_DEVICES_FAILURE'

export const APPROVE_INVOICE_REQUEST = 'companies/APPROVE_INVOICE_REQUEST'
export const APPROVE_INVOICE_SUCCESS = 'companies/APPROVE_INVOICE_SUCCESS'
export const APPROVE_INVOICE_FAILURE = 'companies/APPROVE_INVOICE_FAILURE'

export const REASSIGN_DEVICE_REQUEST = 'companies/REASSIGN_DEVICE_REQUEST'
export const REASSIGN_DEVICE_SUCCESS = 'companies/REASSIGN_DEVICE_SUCCESS'
export const REASSIGN_DEVICE_FAILURE = 'companies/REASSIGN_DEVICE_FAILURE'

export const FETCH_COMPANY_INFO_REQUEST = 'companies/FETCH_COMPANY_INFO_REQUEST'
export const FETCH_COMPANY_INFO_SUCCESS = 'companies/FETCH_COMPANY_INFO_SUCCESS'
export const FETCH_COMPANY_INFO_FAILURE = 'companies/FETCH_COMPANY_INFO_FAILURE'

export const SEND_MONEY_REQUEST = 'companies/SEND_MONEY_REQUEST'
export const SEND_MONEY_SUCCESS = 'companies/SEND_MONEY_SUCCESS'
export const SEND_MONEY_FAILURE = 'companies/SEND_MONEY_FAILURE'

// export const EXPENSE_MONEY_REQUEST = 'companies/EXPENSE_MONEY_REQUEST'
// export const EXPENSE_MONEY_SUCCESS = 'companies/EXPENSE_MONEY_SUCCESS'
// export const EXPENSE_MONEY_FAILURE = 'companies/EXPENSE_MONEY_FAILURE'

export const CHANGE_DELIVERY_ADDRESS_REQUEST = 'companies/CHANGE_DELIVERY_ADDRESS_REQUEST'
export const CHANGE_DELIVERY_ADDRESS_SUCCESS = 'companies/CHANGE_DELIVERY_ADDRESS_SUCCESS'
export const CHANGE_DELIVERY_ADDRESS_FAILURE = 'companies/CHANGE_DELIVERY_ADDRESS_FAILURE'

export const CHANGE_COMPANY_NAME_REQUEST = 'companies/CHANGE_COMPANY_NAME_REQUEST'
export const CHANGE_COMPANY_NAME_SUCCESS = 'companies/CHANGE_COMPANY_NAME_SUCCESS'
export const CHANGE_COMPANY_NAME_FAILURE = 'companies/CHANGE_COMPANY_NAME_FAILURE'

export const CLEAR_COMPANY_DATA = 'companies/CLEAR_COMPANY_DATA'
export const SET_SELECTED_COMPANY = 'companies/SET_SELECTED_COMPANY'

// pro/developer service
export const COMPANY_SERVICE_REQUEST = 'companies/COMPANY_SERVICE_REQUEST'
export const COMPANY_SERVICE_SUCCESS = 'companies/COMPANY_SERVICE_SUCCESS'
export const COMPANY_SERVICE_FAILURE = 'companies/COMPANY_SERVICE_FAILURE'

export const POST_COMPANY_SERVICE_REQUEST = 'companies/POST_COMPANY_SERVICE_REQUEST'
export const POST_COMPANY_SERVICE_SUCCESS = 'companies/POST_COMPANY_SERVICE_SUCCESS'
export const POST_COMPANY_SERVICE_FAILURE = 'companies/POST_COMPANY_SERVICE_FAILURE'

export const DELETE_COMPANY_SERVICE_REQUEST = 'companies/DELETE_COMPANY_SERVICE_REQUEST'
export const DELETE_COMPANY_SERVICE_SUCCESS = 'companies/DELETE_COMPANY_SERVICE_SUCCESS'
export const DELETE_COMPANY_SERVICE_FAILURE = 'companies/DELETE_COMPANY_SERVICE_FAILURE'

export const COMPANY_SERVICE_CATALOG_REQUEST = 'companies/COMPANY_SERVICE_CATALOG_REQUEST'
export const COMPANY_SERVICE_CATALOG_SUCCESS = 'companies/COMPANY_SERVICE_CATALOG_SUCCESS'
export const COMPANY_SERVICE_CATALOG_FAILURE = 'companies/COMPANY_SERVICE_CATALOG_FAILURE'
