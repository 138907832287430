import * as types from './types'

const initialState = {
  list: [],
  error: null,
  msg: null,
  invoices: null,
  payload: null,
  devices: null,
  pendingDevices: null,
  totalPages: null, //devices pages
  about: null,
  sendingMoney: false,
  servicesCatalog: undefined,
  services: {
    pending: false,
    services: undefined,
  },
}

export default function companies(state = initialState, { type, payload }) {
  switch (type) {
    case types.FETCH_COMPANIES_SUCCESS:
      return { ...state, list: payload.companies }
    case types.FETCH_COMPANIES_FAILURE:
      return { ...state, error: payload.error }
    case types.CREATE_COMPANY_FAILURE:
      return { ...state, error: payload.error }
    case types.LINK_DEVICE_TO_COMPANY_SUCCESS:
      return { ...state, msg: payload.msg }
    case types.LINK_DEVICE_TO_COMPANY_FAILURE:
      return { ...state, error: payload.error }
    case types.FETCH_INVOICES_SUCCESS:
      return { ...state, invoices: payload.invoices }
    case types.FETCH_INVOICES_FAILURE:
      return { ...state, error: payload.error }
    case types.FETCH_COMPANY_DEVICES_REQUEST:
      return { ...state, devices: null }
    case types.FETCH_COMPANY_DEVICES_SUCCESS:
      return {
        ...state,
        devices: payload.devices,
        totalPages: payload.totalPages,
        pendingDevices: payload.pending,
      }
    case types.FETCH_COMPANY_DEVICES_FAILURE:
      return { ...state, error: payload.error }
    case types.CLEAR_COMPANY_DATA:
      return { ...state, invoices: null, devices: null }
    case types.APPROVE_INVOICE_SUCCESS:
      return { ...state, msg: payload.msg }
    case types.APPROVE_INVOICE_FAILURE:
      return { ...state, msg: payload.error }
    case types.SEND_MONEY_REQUEST:
      return { ...state, sendingMoney: true }
    case types.SEND_MONEY_SUCCESS:
      return { ...state, msg: payload.msg, sendingMoney: false }
    case types.SEND_MONEY_FAILURE:
      return { ...state, msg: payload.error, sendingMoney: false }
    case types.CHANGE_DELIVERY_ADDRESS_SUCCESS:
      return { ...state, msg: payload.msg }
    case types.CHANGE_DELIVERY_ADDRESS_FAILURE:
      return { ...state, error: payload.error }
    case types.CHANGE_COMPANY_NAME_SUCCESS:
      return { ...state, msg: payload.msg }
    case types.CHANGE_COMPANY_NAME_FAILURE:
      return { ...state, error: payload.error }

    case types.COMPANY_SERVICE_REQUEST:
      return { ...state, services: { ...state.services, pending: true } }
    case types.COMPANY_SERVICE_SUCCESS:
      return {
        ...state,
        services: { ...state.services, pending: false, services: payload.services },
      }
    case types.COMPANY_SERVICE_FAILURE:
      return { ...state, services: { ...state.services, pending: false }, error: payload.error }

    case types.POST_COMPANY_SERVICE_REQUEST:
      return { ...state, services: { ...state.services, pending: true } }
    case types.POST_COMPANY_SERVICE_SUCCESS:
      return { ...state, services: { ...state.services, pending: false }, msg: payload.msg }
    case types.POST_COMPANY_SERVICE_FAILURE:
      return { ...state, services: { ...state.services, pending: false }, error: payload.error }

    case types.DELETE_COMPANY_SERVICE_REQUEST:
      return { ...state, services: { ...state.services, pending: true } }
    case types.DELETE_COMPANY_SERVICE_SUCCESS:
      return {
        ...state,
        services: { ...state.services, pending: false, services: undefined },
        msg: payload.msg,
      }
    case types.DELETE_COMPANY_SERVICE_FAILURE:
      return { ...state, services: { ...state.services, pending: false }, error: payload.error }

    case types.SET_SELECTED_COMPANY:
      return { ...state, selected: payload.company }
    case types.REASSIGN_DEVICE_SUCCESS:
      return {
        ...state,
        msg: payload.msg,
      }
    case types.REASSIGN_DEVICE_FAILURE:
      return { ...state, error: payload.error }
    case types.FETCH_COMPANY_INFO_REQUEST:
      return { ...state, about: null }
    case types.FETCH_COMPANY_INFO_SUCCESS:
      return {
        ...state,
        about: payload.info,
      }
    case types.COMPANY_SERVICE_CATALOG_REQUEST:
      return { ...state }
    case types.COMPANY_SERVICE_CATALOG_SUCCESS:
      return { ...state, servicesCatalog: payload.catalog }
    case types.COMPANY_SERVICE_CATALOG_FAILURE:
      return {
        ...state,
        error: payload.error,
      }

    case 'ui/CLEAR_MESSAGES':
      return { ...state, msg: null, error: null }
    default:
      return state
  }
}
