import * as React from 'react'
import Table from '@material-ui/core/Table'

import TableHead from './TableHead'
import TableBody from './TableBody'
import TablePagination from './TablePagination'
import TableToolbar from './TableToolbar'

const DevicesTable = props => (
  <div style={{ display: 'flex', flexDirection: 'column' }}>
    <TableToolbar {...props} />
    <Table
      style={{
        width: '100%',
        tableLayout: 'fixed',
      }}
    >
      <TableHead {...props} />
      <TableBody {...props} />
    </Table>
    <TablePagination {...props} />
  </div>
)

export default DevicesTable
