//@flow
import * as React from 'react'
import styled from 'styled-components'
import { Container, Wrapper } from 'sputnik-ui'

const Page = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100vw - (100vw - 100%));
  padding-top: 2rem;
  /* padding-bottom: 2rem; */
  overflow-x: hidden;
  height: auto;
  min-height: 100%;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Tabs = styled.div`
  display: flex;
  margin-bottom: 2rem;
  margin-top: 2rem;
`

const Separator = styled.div`
  /* height: 4px; */
  width: 100%;
  /* background: ${(p) => p.theme.palette.secondary['500']}; */
  /* margin: 2rem 0; */
  margin-top:2rem;
  border-bottom: solid 1px ${(p) => p.theme.palette.grayscale[0]};
`
const Actions = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 1rem;
  && {
    & > * {
      margin-right: 1rem;
    }
  }
`

const Background = styled.div`
  /* background: ${(p) => p.theme.palette.grayscale[2]}; */
  padding-bottom: 2rem;
  flex: 1;
`

const CompaniesTemplate = ({
  tabs,
  children,
  select,
  actions,
  selectedCompany,
  ...props
}: Props) => {
  return (
    <Page {...props}>
      <Container>
        <Wrapper shadow={!selectedCompany} style={{ flexDirection: 'column' }}>
          <div
            style={{
              // maxWidth: 600,
              // minWidth: '50%',
              // width: '50%',
              flex: 1,
              margin: '0 auto',
              marginLeft: 0,
              width: '100%',
            }}
          >
            {select}
          </div>
          {actions && <Actions>{actions}</Actions>}
        </Wrapper>
      </Container>

      {selectedCompany && <Separator />}

      {tabs && (
        <Background>
          <Container>
            {tabs && <Tabs>{tabs}</Tabs>}

            <ContentWrapper>{children}</ContentWrapper>
          </Container>
        </Background>
      )}
    </Page>
  )
}

type Props = {
  tabs: React.Node,
  children: React.Node,
  select: React.Node,
  header: React.Node,
  selectedCompany: boolean,
}

export default CompaniesTemplate
