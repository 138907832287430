import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Typography } from '@material-ui/core'
import styled from 'styled-components'
import DevicesTable from '../DevicesTable'
import DevicesList from 'features/Devices/DevicesList'
import TransactionHistory from 'features/Operations/TransactionHistory'
import { Loader, Wrapper, Options } from 'sputnik-ui'
import {
  fetchTransactions,
  fetchAvailablePeriod,
  clearOptions,
} from 'store/operations/operations'
import TransactionHistoryTemplate from 'components/templates/TransactionHistoryTemplate'

const StyledWrapper = styled(Wrapper)`
  background: ${(p) => p.theme.palette.white['500']};
  padding: 0;
  overflow: hidden;
  flex-direction: column;
`
const Subsection = styled.div`
  padding: 1rem;
  flex-direction: column;
`

function Operations({ balance, pending, dispatch, years, uuid }) {
  const [selectedYear, setSelectedYear] = useState(null)

  useEffect(() => {
    if (uuid && typeof uuid == 'string') {
      dispatch(fetchTransactions(uuid, selectedYear))
      dispatch(fetchAvailablePeriod(uuid))
    }
    return () => dispatch(clearOptions())
  }, [dispatch, uuid, selectedYear])

  if (years && !selectedYear) {
    setSelectedYear(years[0])
  }

  // const fadeLeft = useTransition(true, null, {
  //   from: { opacity: 0, transform: 'translate3d(450px,0,0)' },
  //   leave: { opacity: 0, transform: 'translate3d(450px,0,0)' },
  //   enter: { opacity: 1, transform: 'translate3d(0,0,0)' },
  // })

  // const fadeRight = useTransition(location, (location) => location.pathname, {
  //   from: { opacity: 0, transform: 'translate3d(-250px,0,0)' },
  //   leave: {
  //     display: 'none',
  //   },
  //   enter: { opacity: 1, transform: 'translate3d(0,0,0)' },
  // })

  // if (!billingAccount) return <Loader />
  if (!uuid || !years) return <Loader />
  return (
    <TransactionHistoryTemplate
      aside={
        <>
          <StyledWrapper>
            <Subsection>
              <Typography style={{ marginBottom: '1rem' }}>
                <span>
                  Баланс{' '}
                  <span style={balance < 0 ? { color: 'red' } : null}>
                    {balance}
                  </span>{' '}
                  руб.
                </span>
              </Typography>
              <Options
                options={years || []}
                value={selectedYear}
                onChange={setSelectedYear}
              />
            </Subsection>
          </StyledWrapper>
        </>
      }
    >
      <TransactionHistory selectedYear={selectedYear} />
    </TransactionHistoryTemplate>
  )
}

const mapStateToProps = ({ companies, operations }) => ({
  //   pending: companies.pendingDevices,
  balance: companies.about?.balance,
  years: operations.period,
  uuid: companies.about?.uuid,
})
export default connect(mapStateToProps)(Operations)
