import * as types from './types'

const initialState = {
  account: null,
  list: null,
  period: null,
  statistics: null,
  error: null,
  msg: null,
  loading: false,
}

export default function operations(state = initialState, { type, payload }) {
  switch (type) {
    case types.CLEAR_TRANSACTIONS_DATA:
      return { ...state, error: null, list: null, msg: null }
    case types.FETCH_STATISTICS_SUCCESS:
      return { ...state, statistics: payload.statistics }
    case types.FETCH_AVAILABLE_PERIOD_FAILURE:
      return { ...state, error: payload.error }
    case types.FETCH_TRANSACTIONS_SUCCESS:
      return { ...state, list: payload.transactions }
    case types.FETCH_TRANSACTIONS_FAILURE:
      return { ...state, error: payload.error }
    case types.FETCH_BILLING_ACCOUNT_SUCCESS:
      return { ...state, account: payload.account }
    case types.FETCH_BILLING_ACCOUNT_FAILURE:
      return { ...state, error: payload.error }
    case types.CHANGE_BILLING_ACCOUNT_INFO_SUCCESS:
      return { ...state, account: payload.account }
    case types.CHANGE_BILLING_ACCOUNT_INFO_FAILURE:
      return { ...state, error: payload.error }
    case types.FETCH_AVAILABLE_PERIOD_SUCCESS:
      return { ...state, period: payload.period }
    // case types.UNBIND_PAYMENT_METHOD_SUCCESS:
    //   return { ...state, msg: payload.msg }
    // case types.UNBIND_PAYMENT_METHOD_FAILURE:
    //   return { ...state, error: payload.error }
    // case types.MAKE_DEPOSIT_REQUEST:
    //   return { ...state, loading: true, msg: null, error: null }
    // case types.MAKE_DEPOSIT_SUCCESS:
    //   return { ...state, msg: payload.msg, loading: false }
    // case types.MAKE_DEPOSIT_FAILURE:
    //   return { ...state, error: payload.error, loading: false }
    case 'ui/CLEAR_MESSAGES':
      return { ...state, msg: null, error: null }
    default:
      return state
  }
}
