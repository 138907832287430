import React from 'react'
import PropTypes from 'prop-types'
import columnData from './columnData'

import {
  TableSortLabel,
  TableHead,
  TableCell,
  TableRow,
  Tooltip,
  withStyles,
  Checkbox,
} from '@material-ui/core'

import {
  DevicesOther,
  RoomService,
  FiberManualRecord,
  Gavel,
} from '@material-ui/icons'

const styles = theme => ({
  cell: {
    // backgroundColor: theme.palette.primary['main'],
    '&:first-child': {
      width: 48,
    },
  },
  label: {
    // color: 'white',
    // '&:focus': {
    //   color: 'white',
    // },
    // '&:hover': {
    //   color: 'white',
    // },
    '& svg': {
      marginRight: '.325rem',
    },
  },
})

class DevicesTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.handleSortRequest(event, property)
  }

  render() {
    const {
      order,
      orderBy,
      classes,
      handleSelectAll,
      selected,
      devices,
    } = this.props

    const rowCount = (devices && devices.length) || 0

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox" className={classes.cell}>
            <Checkbox
              indeterminate={selected.length > 0 && selected.length < rowCount}
              checked={selected.length === rowCount && rowCount > 0}
              onChange={handleSelectAll}
              color="primary"
            />
          </TableCell>
          {columnData.map(column => {
            const active = orderBy === column.id
            return (
              <TableCell
                key={column.id}
                numeric={column.numeric}
                padding={column.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === column.id ? order : false}
                className={classes.cell}
                id={`table-head-${column.id}`}
                onClick={handleSelectAll}
              >
                <Tooltip
                  title="Сортировать"
                  placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={active}
                    direction={order}
                    onClick={this.createSortHandler(column.id)}
                    className={classes.label}
                  >
                    {column.id === 'serial_number' && <DevicesOther />}
                    {column.id === 'services_count' && <RoomService />}
                    {column.id === 'is_online' && <FiberManualRecord />}
                    {column.id === 'installed' && <Gavel />}

                    {column.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            )
          })}
        </TableRow>
      </TableHead>
    )
  }
}

DevicesTableHead.propTypes = {
  handleSortRequest: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}

export default withStyles(styles)(DevicesTableHead)
