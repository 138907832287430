import React from 'react'
import styled from 'styled-components'
import { ChevronLeft, ChevronRight } from '@material-ui/icons'
import { Typography, IconButton, ListItemText } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { Container } from 'sputnik-ui'
import { incPage, decPage } from './devicesSlice'

const Body = styled.div`
  && {
    position: sticky;
    bottom: 0;
    display: flex;
    align-items: center;

    backdrop-filter: blur(20px) saturate(180%);
    background: rgba(255, 255, 255, 0.4);
    border-top: 1px solid ${(p) => p.theme.palette.background.secondary};
    z-index: 3;
    padding: 0;

    /* box-shadow: rgba(0,0,0,0.1) 0px -13px 20px -14px; */
  }
`

export default function DevicesBottombar() {
  const dispatch = useDispatch()
  const total = useSelector(({ devices }) => devices.total)
  const totalSum = useSelector(({ devices }) => devices.totalSum)
  const pages = useSelector(({ devices }) => devices.pages)
  const page = useSelector(({ devices }) => devices.page)

  return (
    <Body>
      <IconButton onClick={() => dispatch(decPage())} disabled={page === 1}>
        <ChevronLeft />
      </IconButton>
      <Typography>{page} of {pages}</Typography>
      <IconButton onClick={() => dispatch(incPage())} disabled={page === pages}>
        <ChevronRight />
      </IconButton>
      {totalSum && total && (
        <>
          <Typography
            align="right"
            style={{ padding: '.315rem 0', marginLeft: 'auto' }}
            color="textSecondary">
            за {total} устройств
          </Typography>
          <Typography variant="h5" align="right" style={{ padding: '.625rem' }}>
            {totalSum} ₽
          </Typography>
        </>
      )}
      {/* <ListItemText
          style={{ marginLeft: 'auto' }}
          primary={`500 ₽`}
          secondary={`За 50 устройств`}
          primaryTypographyProps={{ align: 'right' }}
          secondaryTypographyProps={{ align: 'right' }}
        ></ListItemText> */}
    </Body>
  )
}
