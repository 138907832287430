//@flow
import * as React from 'react'
import { TransactionStatus } from 'components'
import { TableCell, IconButton, Tooltip } from '@material-ui/core'
import { Done } from '@material-ui/icons'
import type { ITransaction } from 'globalTypes'

const dateFormatOptions = {
  // year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
}

const Transaction = ({
  transaction,
  onApprove,
}: {
  transaction: ITransaction,
  onApprove?: Function,
}) => {
  return (
    <>
      <TableCell
        style={{ ...styles.cell, textAlign: 'left', fontWeight: 'bold' }}
      >
        #{transaction.operation_id}
      </TableCell>
      <TableCell style={styles.cell}>
        <TransactionStatus status={transaction.status} />
      </TableCell>

      <TableCell style={styles.cell}>
        {new Date(transaction.date).toLocaleDateString(
          undefined,
          dateFormatOptions,
        )}
      </TableCell>
      <TableCell
        style={{ ...styles.cell, fontWeight: 'bold', textAlign: 'right' }}
      >
        {transaction.amount}
      </TableCell>
      {onApprove && (
        <TableCell style={{ ...styles.cell, fontWeight: 'bold' }}>
          <Tooltip title="Одобрить транзакцию" placement="left">
            <IconButton onClick={onApprove(transaction.operation_id)}>
              <Done style={{ color: 'green' }} />
            </IconButton>
          </Tooltip>
        </TableCell>
      )}
    </>
  )
}

const styles = {
  cell: {
    borderBottom: 'none',
    textAlign: 'center',
    verticalAlign: 'middle',
    padding: 0,
  },
  center: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: 8,
  },
}

export default Transaction
