import React from 'react'
import styled from 'styled-components'
import { LocalOffer } from '@material-ui/icons'

const TagWrapper = styled.span`
  display: inline-flex;
  /* border: ${p => p.theme}; */
  padding: ${p => p.theme.sizes.scale[1] + ' ' + p.theme.sizes.scale[1]};
  font-family: ${p => p.theme.fonts.primary};
  color: ${p => p.theme.palette.text.primary};
  font-size: ${p => p.theme.sizes.scale[3]};
  /* border: 1px solid ${p => p.theme.palette.text.secondary}; */
  border-radius:4px;
  align-items:center;
  background: ${p => p.theme.palette.secondary[50]};
`

const Tag = ({ children, ...props }) => (
  <TagWrapper {...props}>
    <LocalOffer color="primary" style={{ marginRight: 4, fontSize: 18 }} />
    {children}
  </TagWrapper>
)

export default Tag
