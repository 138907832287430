// https://github.com/diegohaz/arc/wiki/Atomic-Design#templates
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: calc(100vw - (100vw - 100%));
`

const Header = styled.header`
  position: static;
  top: 0;
  width: 100%;
  z-index: 999;
  /* height: 7.425rem; */
`

const Content = styled.section`
  min-height: calc(100% - 7.425rem);
  height: 100%;
`

const AppTemplate = ({ header, children, subheader, ...props }) => {
  return (
    <Wrapper {...props}>
      <Header>{header}</Header>
      <Content>{children}</Content>
    </Wrapper>
  )
}

AppTemplate.propTypes = {
  header: PropTypes.node.isRequired,
  subheader: PropTypes.node,
  children: PropTypes.any.isRequired,
}

export default AppTemplate
