/* @flow */
import * as types from './types'
import type { Action } from 'globalTypes'

export const signIn = (user: Object): Action => ({
  type: types.SIGN_IN,
  payload: { user },
})
export const signInError = (): Action => ({
  type: types.SIGN_IN_ERROR,
  payload: {},
})
export const checkAuth = (): Action => ({
  type: types.CHECK_AUTH,
  payload: {},
})

export const logOutRequest = (): Action => ({
  type: types.LOG_OUT_REQUEST,
  payload: {},
})
export const logOutSuccess = (): Action => ({
  type: types.LOG_OUT_SUCCESS,
  payload: {},
})
export const logOutError = (): Action => ({
  type: types.LOG_OUT_ERROR,
  payload: {},
})

export const fetchUserCompanyRequest = (): Action => ({
  type: types.FETCH_USER_COMPANY_REQUEST,
  payload: {},
})
export const fetchUserCompanySuccess = (company: Object): Action => ({
  type: types.FETCH_USER_COMPANY_SUCCESS,
  payload: { company },
})
export const fetchUserCompanyError = (): Action => ({
  type: types.FETCH_USER_COMPANY_FAILURE,
  payload: {},
})
