//@flow
//$FlowFixMe
import React from 'react'
import { connect } from 'react-redux'
import { Button } from 'sputnik-ui'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  TextField,
} from '@material-ui/core'

import { Formik } from 'formik'
import * as Yup from 'yup'

import { createCompany, fetchCompanies } from 'store/operations'

const Transition = props => <Slide direction="up" {...props} />

const validationSchema = Yup.object().shape({
  company_name: Yup.string()
    .typeError('должен быть строкой')
    .required('введите название компании'),
  company_tin: Yup.string()
    .typeError('должен состоять из цифр')
    .required('введите инн')
    .test(
      'len',
      'должен состоять из 10-13 цифр',
      val =>
        val &&
        (val.toString().length === 10 ||
          val.toString().length === 12 ||
          val.toString().length === 13),
    ),
  email: Yup.string()
    .required('введите email')
    .email('неверный формат'),
})

const CreateCompanyModal = ({ open, handleClose, dispatch }: Props) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      PaperProps={{ style: styles.dialogWindow }}
      onExit={() => {}}
    >
      <DialogTitle>Создание компании</DialogTitle>
      <Formik
        validationSchema={validationSchema}
        initialValues={{ email: '', company_name: '', company_tin: '' }}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            await dispatch(createCompany(values))
            await dispatch(fetchCompanies())
            handleClose()
          } catch (error) {
            setSubmitting(false)
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent style={{ overflow: 'visible' }}>
              <TextField
                name="company_name"
                label="Название компании"
                fullWidth
                margin="dense"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.company_name && !!errors.company_name}
                helperText={touched.company_name && errors.company_name}
                value={values.company_name}
              />
              <TextField
                name="email"
                label="Почта"
                fullWidth
                margin="dense"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.email && !!errors.email}
                helperText={touched.email && errors.email}
                value={values.email}
              />
              <TextField
                name="company_tin"
                label="ИНН"
                fullWidth
                margin="dense"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.company_tin && !!errors.company_tin}
                helperText={touched.company_tin && errors.company_tin}
                value={values.company_tin}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClose}
                secondary
                rounded
                size="small"
                tabIndex={-1}
              >
                Отменить
              </Button>
              <Button
                rounded
                primary
                size="small"
                type="submit"
                disabled={isSubmitting}
              >
                создать компанию
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  )
}

type Props = {
  open: boolean,
  handleClose: Function,
  dispatch: Function,
}

const styles = {
  dialogWindow: { overflow: 'visible', minWidth: 418 },
}

export default connect()(CreateCompanyModal)
