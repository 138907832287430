import React, { Component } from 'react'
import { connect } from 'react-redux'
import { checkAuth } from 'store/auth/operations'
import { Loader } from 'sputnik-ui'

export default function requireAuth(C) {
  class Authentication extends Component {
    componentDidMount() {
      // check session
      this.props.dispatch(checkAuth())
    }

    render() {
      const { isLoggedIn } = this.props

      if (!isLoggedIn) {
        return <Loader fullPage msg="Проверяем авторизацию..." />
      }

      return <C {...this.props} />
    }
  }

  const mapStateToProps = ({ auth }) => ({
    isLoggedIn: auth.isLoggedIn,
  })

  return connect(mapStateToProps)(Authentication)
}
