import * as types from './types'

const initialState = {
  info: null,
}

export default function ui(state = initialState, { type, payload }) {
  switch (type) {
    case types.SHOW_INFO_MESSAGE:
      return { ...state, info: payload }
    case types.CLEAR_MESSAGES:
      return initialState
    default:
      return state
  }
}
