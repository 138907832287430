import React from 'react'
import TablePagination from '@material-ui/core/TablePagination'

export default props => (
  <TablePagination
    component="div"
    style={{
      // position: 'fixed',
      // bottom: 0,
      // left: 0,
      // right: 0,
      background: 'white',
    }}
    count={props.totalPages * props.rowsPerPage}
    rowsPerPage={props.rowsPerPage}
    rowsPerPageOptions={[10, 20, 30]}
    page={props.page}
    labelRowsPerPage="Строк на странице:"
    labelDisplayedRows={({ from, to, count }) => `${from}-${to} из ${count}`}
    onChangePage={props.handleChangePage}
    onChangeRowsPerPage={props.handleChangeRowsPerPage}
  />
)
