import React, { useEffect } from 'react'
import { Wrapper, useInput } from 'sputnik-ui'
import styled from 'styled-components'
import {
  InputBase,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  IconButton,
  CircularProgress,
} from '@material-ui/core'
import { RemoveCircle } from '@material-ui/icons'
import { connect } from 'react-redux'
import {
  fetchTracks,
  addTrack,
  removeTrack,
} from '../../../store/tracks/operations'

const StyledWrapper = styled(Wrapper)`
  background: ${p => p.theme.palette.white['500']};
  padding: 0;
  overflow: hidden;
  flex-direction: column;
`

const Input = styled(InputBase).attrs(p => ({
  inputProps: {
    style: { padding: 0 },
  },
}))`
  && {
    padding: ${p => p.theme.sizes.scale[6]};
    background: ${p => p.theme.palette.secondary['100']};
    border-radius: 8px 0 0 8px;
    transition-property: background-color, border-color;
    transition-duration: 0.25s;
    transition-timing-function: ease;
    flex: 1;

    &:hover,
    &:focus,
    &:active {
      background: ${p => p.theme.palette.grayscale[1]};
    }

    &:focus-within {
      background: ${p => p.theme.palette.secondary['50']};
    }
  }
`

const SubmitButton = styled.button`
  border-radius: 0 8px 8px 0;
  font-family: ${p => p.theme.fonts.primary};
  border: 0;
  background: ${p => p.theme.palette.primary['500']};
  color: ${p => p.theme.palette.white['500']};
  padding: 0 2rem;
  cursor: pointer;
  outline: none;

  &:hover {
    background: ${p => p.theme.palette.grayscale[0]};
  }
`

const Tracks = ({ dispatch, selectedCompanyUuid, tracks }) => {
  const { value: trackNum, bind: bindTrackNum, reset } = useInput('')

  const handleSubmit = e => {
    e.preventDefault()
    dispatch(addTrack(selectedCompanyUuid, trackNum)).then(() => {
      reset()
      dispatch(fetchTracks(selectedCompanyUuid))
    })
  }
  const handleDelete = trackId => {
    dispatch(removeTrack(trackId)).then(() =>
      dispatch(fetchTracks(selectedCompanyUuid)),
    )
  }

  useEffect(() => {
    dispatch(fetchTracks(selectedCompanyUuid))
  }, [dispatch, selectedCompanyUuid])

  return (
    <StyledWrapper
      border={false}
      style={{ flexDirection: 'column', padding: 0 }}
    >
      <form style={{ display: 'flex' }} onSubmit={handleSubmit}>
        <Input placeholder="трек-номер" {...bindTrackNum} />
        <SubmitButton>добавить</SubmitButton>
      </form>

      <List>
        {tracks ? (
          tracks.map(t => (
            <ListItem key={t.id} dense style={{ width: '100%' }} disableGutters>
              <Tooltip title="Удалить" placement="bottom">
                <IconButton
                  aria-label="Delete"
                  onClick={() => handleDelete(t.id)}
                  style={{ marginRight: '.325rem' }}
                >
                  <RemoveCircle color="error" />
                </IconButton>
              </Tooltip>
              <ListItemText
                primaryTypographyProps={{
                  style: {
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  },
                }}
                primary={t.number}
                secondary={new Date(t.created_at).toLocaleString()}
              />
            </ListItem>
          ))
        ) : (
          <CircularProgress />
        )}
      </List>
    </StyledWrapper>
  )
}

const mapStateToProps = ({ tracks, companies }) => ({
  tracks: tracks.list,
  selectedCompanyUuid: companies.selected.value,
})

export default connect(mapStateToProps)(Tracks)
