import * as types from './types'
import { CLEAR_MESSAGES } from '../ui/types'

const initialState = {
  list: null,
  error: null,
  msg: null,
}

export default function tracks(state = initialState, { type, payload }) {
  switch (type) {
    case types.FETCH_TRACKS_REQUEST:
      return { ...state, list: null }
    case types.FETCH_TRACKS_SUCCESS:
      return {
        ...state,
        list: payload.tracks,
      }
    case types.ADD_TRACK_FAILURE:
      return {
        ...state,
        error: payload.error,
      }
    case types.REMOVE_TRACK_FAILURE:
      return {
        ...state,
        error: payload.error,
      }
    case CLEAR_MESSAGES:
      return { ...state, error: null, msg: null }
    default:
      return state
  }
}
