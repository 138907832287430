import React from 'react'
import range from 'lodash/range'
import styled from 'styled-components'
import Skeleton from 'react-loading-skeleton'

import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Checkbox from '@material-ui/core/Checkbox'

import { FiberManualRecord } from '@material-ui/icons'

const OnlineIcon = styled(FiberManualRecord)`
  color: ${p =>
    p.isOnline ? p.theme.palette.success['500'] : p.theme.palette.error['500']};
`

export default props => (
  <TableBody>
    {props.devices
      ? props.devices
          .sort(props.getSorting(props.order, props.orderBy))
          .map(row => {
            const isSelected = props.selected.includes(row.duuid)
            return (
              <TableRow
                tabIndex={-1}
                key={row.duuid}
                selected={isSelected}
                onClick={e => props.handleSelect(e, row.duuid, isSelected)}
              >
                <TableCell padding="checkbox">
                  <Checkbox checked={isSelected} color="primary" />
                </TableCell>
                <TableCell component="th" scope="row" padding="dense">
                  {row.serial_number}
                </TableCell>
                <TableCell component="th" scope="row" padding="dense">
                  {row.services_count}
                </TableCell>
                <TableCell component="th" scope="row" padding="dense">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <OnlineIcon isOnline={row.is_online} style={styles.icon} />
                    {row.is_online ? 'онлайн' : 'оффлайн'}
                  </div>
                </TableCell>
                <TableCell component="th" scope="row" padding="dense">
                  {row.installed ? 'установлено' : 'не установлено'}
                </TableCell>
                {/* <TableCell component="th" scope="row" padding="none">
                <Tooltip
                  placement="left"
                  title="Перепривязать на другую компанию"
                  onClick={() =>
                    props.openReassignIntercomModal({
                      name: row.serial_number,
                      uuid: row.duuid,
                    })
                  }
                >
                  <IconButton disableRipple style={{ marginLeft: 'auto' }}>
                    <SubdirectoryArrowRight />
                  </IconButton>
                </Tooltip>
              </TableCell> */}
              </TableRow>
            )
          })
      : range(10).map(key => (
          <TableRow key={key}>
            <TableCell component="th" scope="row" padding="dense">
              <Skeleton />
            </TableCell>
            <TableCell component="th" scope="row" padding="dense">
              <Skeleton />
            </TableCell>
            <TableCell component="th" scope="row" padding="dense">
              <Skeleton />
            </TableCell>
            <TableCell component="th" scope="row" padding="dense">
              <Skeleton />
            </TableCell>
            <TableCell component="th" scope="row" padding="dense">
              <Skeleton />
            </TableCell>
          </TableRow>
        ))}
  </TableBody>
)

const styles = {
  icon: {
    width: '0.8125rem',
    height: '0.8125rem',
    marginRight: 8,
  },
}
