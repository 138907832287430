//@flow
import React from 'react'
import styled from 'styled-components'

const Status = styled.div`
  display: inline-flex;
  align-items: center;
  user-select: none;
  vertical-align: middle;
  justify-content: center;
  padding: 7px 9px;
  min-width: 64px;
  font-size: 0.675rem;
  border-radius: 10rem;

  font-family: ${p=>p.theme.fonts.primary};
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  text-align: center;
  white-space: nowrap;

  background: ${p => p.theme.palette.primary['500']};
  background: ${p =>
    p.theme.palette[p.status] && p.theme.palette[p.status]['500']};
  color: white;
`

const textTransalations = {
  success: 'успех',
  error: 'ошибка',
  pending: 'в ожидании',
}

const TransactionStatus = ({ status }: Props) => {
  return <Status status={status}>{textTransalations[status]}</Status>
}

type Props = {
  status: 'pending' | 'success' | 'error',
}

export default TransactionStatus
