import * as React from 'react'
import styled from 'styled-components'
import { Container } from 'sputnik-ui'

const Wrapper = styled(Container)`
  display: flex;
  flex-direction: column;
  padding: 0;
  /* padding-top: 2rem; */
  padding-bottom: 2rem;

  overflow-x: hidden;
  height: auto;
`

const Tabs = styled.div`
  display: flex;
  margin-bottom: 2rem;
`

const ContentWrapper = styled.div`
  display: flex;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`

const Children = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-right: 2rem;

  @media screen and (max-width: 768px) {
    margin-top: 2rem;
    order: 2;
    margin-right: 0;
  }
`

const Aside = styled.aside`
  display: flex;
  flex-direction: column;

  & > *:not(:first-child) {
    margin-top: 2rem;
  }

  @media screen and (max-width: ${(p) => p.theme.sizes.tablet}) {
    & > div:first-child {
      order: 2;
    }

    & > *:not(:nth-child(2)) {
      margin-top: 2rem;
    }
  }
`

const TransactionHistoryTemplate = ({ tabs, children, aside, ...props }) => {
  return (
    <Wrapper {...props}>
      <Tabs>{tabs}</Tabs>
      <ContentWrapper>
        <Children>{children}</Children>
        <Aside>{aside}</Aside>
      </ContentWrapper>
    </Wrapper>
  )
}

export default TransactionHistoryTemplate
