// https://github.com/diegohaz/arc/wiki/Atomic-Design#templates
import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 100%;
  width: calc(100vw - (100vw - 100%));
  padding-top: 2rem;
`

const LinkDeviceTemplate = ({ children, ...props }) => {
  return <Wrapper {...props}>{children}</Wrapper>
}

LinkDeviceTemplate.propTypes = {}

export default LinkDeviceTemplate
