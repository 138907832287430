import React from 'react'
import { connect } from 'react-redux'
import { Wrapper } from 'sputnik-ui'
import { Typography } from '@material-ui/core'
import Transactions from './Transactions'

const TransactionHistory = ({ operations, error, ...props }) => {
  return (
    <Wrapper style={styles.wrapper} data-aos="fade-right">
      {operations && Object.keys(operations?.operations).length === 0 ? (
        <Typography align="center">
          У вас нет платежей за данный период.
        </Typography>
      ) : (
        <Transactions
          {...props}
          operations={operations}
          error={error}
        />
      )}
    </Wrapper>
  )
}

const styles = {
  wrapper: {
    flexDirection: 'column',
  },
}

const mapStateToProps = ({ operations }) => ({
  operations: operations.list,
  error: operations.error,
})

export default connect(mapStateToProps)(TransactionHistory)
