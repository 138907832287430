//@flow
import * as React from 'react'
import ReactDOM from 'react-dom'
import { i18n, Loader, ThemeProvider } from 'sputnik-ui'
import { Provider } from 'react-redux'
import store from 'store/store'
import { BrowserRouter } from 'react-router-dom'
import { register } from './serviceWorker'
import dayjs from 'dayjs'
import calendar from 'dayjs/plugin/calendar'
import updateLocale from 'dayjs/plugin/updateLocale'
import 'dayjs/locale/ru'
import { I18nextProvider } from 'react-i18next'
import App from './App'

dayjs.extend(calendar)
dayjs.extend(updateLocale)

dayjs.locale('ru')

dayjs.updateLocale('ru', {
  calendar: {
    sameDay: '[Сегодня]',
    nextDay: 'Завтра',
    lastDay: 'Вчера',
    nextWeek: 'DD MMMM',
    lastWeek: 'DD MMMM',
    sameElse: 'DD MMMM',
  },
})

const Root = (): React.Node => (
  <Provider store={store}>
    <BrowserRouter>
      <ThemeProvider>
        <I18nextProvider i18n={i18n}>
          <React.Suspense fallback={<Loader />}>
            <App />
          </React.Suspense>
        </I18nextProvider>
      </ThemeProvider>
    </BrowserRouter>
  </Provider>
)

const node = document.getElementById('root')

if (node === null) {
  throw new Error('no node element')
}

ReactDOM.render(<Root />, node)
register()
