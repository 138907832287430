//@flow
//$FlowFixMe
import React, { useEffect, useState } from 'react'
import {
  CompaniesTemplate,
  Invoices,
  Devices,
  AboutCompany,
  SearchIntercomModal,
  SendMoneyModal,
  ExpenseBillingModal,
  LinkDeviceModal,
  Tracks,
  Operations,
} from 'components'
import { connect } from 'react-redux'
import { withRouter, NavLink, Switch, Route } from 'react-router-dom'
import { Typography } from '@material-ui/core'
import { Select, Button } from 'sputnik-ui'
import { AttachMoney, Link } from '@material-ui/icons'

import type { Match } from 'react-router-dom'
import type { BillingAccount } from 'globalTypes'

import {
  fetchCompanies,
  fetchCompanyInvoices,
  fetchCompanyDevices,
  fetchCompanyInfo,
} from 'store/operations'
import { clearCompanyData, setSelectedCompany } from 'store/actions'
import * as _ from 'lodash'
import { DecideServiceModal } from 'features/TariffServiceControl/DecideServiceModal'
import { CloseServiceAccessModal } from 'features/TariffServiceControl/CloseServiceAccessModal'
import { getCompanyService, getServicesCatalog } from 'store/companies/operations'
import { useSelector } from 'react-redux'
import { More, Slash } from 'sputnik-icons'

const Tab = (props) => (
  <Typography
    variant="body1"
    component={NavLink}
    {...props}
    style={{
      color: 'rgba(0, 0, 0, 0.54)',
      marginRight: '1rem',
      textDecoration: 'none',
      textTransform: 'uppercase',
    }}
    activeStyle={{
      color: 'black',
    }}
  />
)
const isServiceActive = 'activated'

function CompaniesPage({ dispatch, match, invoices, selectedCompany, ...props }: Props) {
  const servicesList = useSelector(({ companies }) => companies.services?.services)
  const isServicesPending = useSelector(({ companies }) => companies.services?.pending)

  useEffect(() => {
    if (selectedCompany) {
      const companyUuid = selectedCompany.value
      dispatch(getServicesCatalog('company'))
      dispatch(getCompanyService(companyUuid))
      dispatch(fetchCompanyInvoices(companyUuid))
      dispatch(fetchCompanyDevices({ companyUuid }))
      dispatch(fetchCompanyInfo({ companyUuid }))
    }

    return () => dispatch(clearCompanyData())
  }, [dispatch, selectedCompany])

  const [intercomSearchOpen, setIntercomSearch] = useState(false)
  const handleIntercomSearch = (values) => {
    try {
      setIntercomSearch(false)
    } catch (error) {}
  }

  const loadCompaniesPromise = (q) => {
    return new Promise((resolve, reject) =>
      dispatch(fetchCompanies(q))
        .then((companies) => {
          const options = companies?.map((o) => ({
            label: o?.name,
            value: o?.uuid,
          }))
          resolve(options)
        })
        .catch(reject)
    )
  }

  const [sendMoneyModal, setSendMoneyModal] = useState(false)
  const [expenseBillingModal, setExpenseBillingModal] = useState(false)
  const [linkDeviceModal, setLinkDeviceModal] = useState(false)
  const [connectServiceModal, setConnectServiceModal] = useState(false)
  const [unconnectServiceModal, setUnconnectServiceModal] = useState(false)

  const activeService = Array.isArray(servicesList)
    ? servicesList.find((service) => service?.state === isServiceActive)
    : undefined

  return (
    <CompaniesTemplate
      selectedCompany={!!selectedCompany}
      tabs={
        selectedCompany && (
          <>
            <Tab exact to={match.url}>
              Устройства
            </Tab>
            <Tab to={`${match.url}/operations`}>Операции</Tab>
            <Tab to={`${match.url}/invoices`}>Инвойсы</Tab>
            <Tab to={`${match.url}/about`}>О Компании</Tab>
            <Tab to={`${match.url}/tracks`}>Треки</Tab>
          </>
        )
      }
      select={
        <Select
          isMulti={false}
          async
          placeholder="название компании, ЛС компании, серийный номер устройства, почта пользователя"
          selected={selectedCompany}
          handleSelect={(o) => dispatch(setSelectedCompany(o))}
          loadOptions={_.debounce(loadCompaniesPromise, 350, { leading: true })}
          defaultOptions
        />
      }
      actions={
        selectedCompany && (
          <>
            <Button size="small" secondary onClick={() => setLinkDeviceModal(true)} rounded>
              <Link style={{ marginRight: 8 }} />
              привязать устройства
            </Button>
            <Button size="small" secondary onClick={() => setSendMoneyModal(true)} rounded>
              <AttachMoney style={{ marginRight: 8 }} />
              насыпать денег
            </Button>
            <Button size="small" secondary onClick={() => setExpenseBillingModal(true)} rounded>
              <AttachMoney style={{ marginRight: 8 }} />
              Списать денег
            </Button>
            {activeService ? (
              <Button
                disabled={isServicesPending}
                size="small"
                secondary
                onClick={() => setUnconnectServiceModal(true)}
                rounded
              >
                <Slash style={{ marginRight: 8, 'min-width': 24 }} />
                Отключить услугу
              </Button>
            ) : (
              <Button
                disabled={isServicesPending}
                size="small"
                secondary
                onClick={() => setConnectServiceModal(true)}
                rounded
              >
                <More style={{ marginRight: 8 }} />
                Подключить услугу
              </Button>
            )}
          </>
        )
      }
    >
      {selectedCompany && (
        <>
          <Switch>
            <Route exact path={`${match.url}/tracks`} component={Tracks} />
            <Route exact path={`${match.url}/about`} component={AboutCompany} />
            <Route path={`${match.url}/invoices`} component={Invoices} />
            <Route path={`${match.url}/operations`} component={Operations} />
            <Route path={match.url} component={Devices} />
          </Switch>
        </>
      )}
      <SearchIntercomModal
        open={intercomSearchOpen}
        handleClose={() => setIntercomSearch(false)}
        handleIntercomSearch={handleIntercomSearch}
      />
      <SendMoneyModal open={sendMoneyModal} handleClose={() => setSendMoneyModal(false)} />
      <LinkDeviceModal open={linkDeviceModal} handleClose={() => setLinkDeviceModal(false)} />
      <ExpenseBillingModal
        open={expenseBillingModal}
        handleClose={() => setExpenseBillingModal(false)}
      />
      <DecideServiceModal
        activeService={activeService}
        open={connectServiceModal}
        handleClose={() => setConnectServiceModal(false)}
      />
      <CloseServiceAccessModal
        activeService={activeService}
        open={unconnectServiceModal}
        onClose={() => setUnconnectServiceModal(false)}
      />
    </CompaniesTemplate>
  )
}

type Props = {
  match: Match,
  dispatch: Function,
  billingAccount: BillingAccount,

  invoices: Object,
  selectedCompany: {
    label: string,
    value: string,
  },
}

const mapStateToProps = ({ auth, companies }) => ({
  invoices: companies.invoices,
  selectedCompany: companies.selected,
})

export default withRouter(connect(mapStateToProps)(CompaniesPage))
