//@flow
//$FlowFixMe
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Button, Select } from 'sputnik-ui'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
} from '@material-ui/core'

import { fetchCompanies, reassignDevice } from 'store/operations'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'

const Transition = (props) => <Slide direction="up" {...props} />

const ReassignIntercomModal = ({ open, onClose }) => {
  const dispatch = useDispatch()
  const devices = useSelector(({ devices }) => Object.keys(devices.selected))
  useEffect(() => {
    dispatch(fetchCompanies())
  }, [dispatch])
  const [selectedCompany, setSelectedCompany] = useState(null)

  const loadCompaniesPromise = (q) => {
    return new Promise((resolve, reject) =>
      dispatch(fetchCompanies(q))
        .then((companies) => {
          const options = companies.map((o) => ({
            label: o.name,
            value: o.uuid,
          }))
          resolve(options)
        })
        .catch(reject),
    )
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      PaperProps={{ style: styles.dialogWindow }}
      onExit={() => {}}
    >
      <DialogTitle>Перепривязка панелей</DialogTitle>
      <DialogContent style={{ overflow: 'visible' }}>
        <Select
          name="company"
          handleSelect={setSelectedCompany}
          selected={selectedCompany}
          placeholder="Название компании"
          isMulti={false}
          async
          loadOptions={loadCompaniesPromise}
          defaultOptions
        />
        {/* {!!errors.company && (
        <Typography color="error" variant="caption">
          {errors.company}
        </Typography>
      )} */}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} secondary rounded size="small" tabIndex={-1}>
          закрыть
        </Button>
        <Button
          rounded
          primary
          size="small"
          onClick={() =>
            dispatch(
              reassignDevice({
                companyName: selectedCompany.label,
                companyUuid: selectedCompany.value,
                devices,
              }),
            ).then(() => {
              console.log('asdad')
              // unselectAll([])
              onClose()
            })
          }
          disabled={!selectedCompany}
        >
          Перепривязать
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const styles = {
  dialogWindow: { overflow: 'visible', minWidth: '30%', flexBasis: '420px' },
}

export default ReassignIntercomModal
