//@flow
//$FlowFixMe
import React from 'react'
import { connect } from 'react-redux'
import { Button, useInput } from 'sputnik-ui'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  TextField,
} from '@material-ui/core'

import {} from 'store/operations'
import { sendMoney } from '../../../store/companies/operations'

const Transition = props => <Slide direction="up" {...props} />

const ReassignIntercomModal = ({
  open,
  selectedCompany,
  sendingMoney,
  handleClose,
  dispatch,
}: Props) => {
  const { value: amount, bind: bindAmount } = useInput('')
  const { value: comment, bind: bindComment } = useInput('')
  const handleSubmit = e => {
    e.preventDefault()
    dispatch(sendMoney({ amount, comment, companyUuid: selectedCompany })).then(
      () => {
        handleClose()
      },
    )
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      PaperProps={{ style: styles.dialogWindow }}
      onExit={() => {}}
    >
      <DialogTitle>Насыпать денег</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent style={{ overflow: 'visible', paddingTop: 0 }}>
          <TextField
            label="Cумма"
            {...bindAmount}
            autoFocus
            fullWidth
            style={{ marginBottom: '.325rem' }}
          />
          <TextField label="Комментарий" {...bindComment} fullWidth />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            secondary
            rounded
            size="small"
            tabIndex={-1}
          >
            закрыть
          </Button>
          <Button
            rounded
            primary
            size="small"
            onClick={handleSubmit}
            disabled={!amount || sendingMoney}
            type="submit"
          >
            насыпать{sendingMoney && '...'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

type MS = {
  selectedCompany: string,
  sendingMoney: boolean,
}

type Props = {
  open: boolean,
  handleClose: Function,
  dispatch: Function,
} & MS

const styles = {
  dialogWindow: { overflow: 'visible', minWidth: 418 },
}

const mapStateToProps = ({ companies }): MS => ({
  companies: companies.list,
  selectedCompany: companies.selected && companies.selected.value,
  sendingMoney: companies.sendingMoney,
})

export default connect(mapStateToProps)(ReassignIntercomModal)
