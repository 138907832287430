import { createStore, applyMiddleware, compose } from 'redux'
import rootReducer from './reducers'
import thunk from 'redux-thunk'
import throttle from 'lodash/throttle'
import { loadState, saveState } from 'sputnik-ui'

const middleware = [thunk]

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose

const enhancer = composeEnhancers(
  applyMiddleware(...middleware)
  // other store enhancers if any
)

const persistedState = loadState()

const store = createStore(rootReducer, persistedState, enhancer)

store.subscribe(throttle(() => saveState(store.getState()), 1000))

export default store
