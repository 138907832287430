import React, { useEffect, useState, useCallback } from 'react'
import { List, Typography, IconButton, ListItem } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { Search, Container, useInput, Select } from 'sputnik-ui'
import Skeleton from 'react-loading-skeleton'

import Device from './Device'
import DevicesToolbar from './DevicesToolbar'
import DevicesBottombar from './DevicesBottombar'
import FiltersWrapper from 'components/atoms/FiltersWrapper'
import FilterButton from 'components/atoms/FilterButton'
// import Select from 'components/atoms/Select'
import DevicePlaceholder from './DevicePlaceholder'

import { fetchDevices, clearDevices, setSearch } from './devicesSlice'
import ReassignIntercomModal from 'components/organisms/ReassignIntercomModal'

import { Loader } from 'sputnik-ui'
import styledComponents from 'styled-components'

const StyledContainer = styledComponents.div`
  background-color: #ccc;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  opacity: 0.2;
`

export default function DevicesList() {
  const dispatch = useDispatch()
  const devices = useSelector(({ devices }) => devices.list)
  const total = useSelector(({ devices }) => devices.total)
  const pending = useSelector(({ devices }) => devices.pending)
  const page = useSelector(({ devices }) => devices.page)
  const limit = useSelector(({ devices }) => devices.limit)
  const search = useSelector(({ devices }) => devices.search)

  const { bind, value: query } = useInput(search)
  useEffect(() => {
    dispatch(setSearch(query))
  }, [dispatch, query])

  const selectedCompany = useSelector(({ companies }) => companies.selected)
  useEffect(() => {
    dispatch(
      fetchDevices({
        page,
        limit,
        // service_partner_uuid: servicePartnerFilter.value,
        company_uuid: selectedCompany.value,
        search,
      }),
    )
  }, [page, limit, search, dispatch, selectedCompany.value])

  useEffect(() => {
    return () => dispatch(clearDevices())
  }, [])

  const [reassignModal, setReassignModal] = useState(false)
  const toggleReassignModal = () => setReassignModal(!reassignModal)

  return (
    <>
      <Search {...bind} placeholder="номер устройства или адрес" />
      {/* <FiltersWrapper>
        <div style={{ width: 250 }}>
          <Select
            options={localities}
            isMulti={false}
            isClearable={false}
            isSearchable={false}
            cacheOptions
            defaultOptions
            value={localityFilter}
            onChange={(o) => {
              dispatch(setLocalityFilter(o))
            }}
          ></Select>
        </div>
        <div style={{ width: 250 }}>
          <Select
            options={servicePartners}
            isMulti={false}
            isClearable={false}
            isSearchable={false}
            cacheOptions
            defaultOptions
            value={servicePartnerFilter}
            onChange={(o) => {
              dispatch(setServicePartnerFilter(o))
            }}
          ></Select>
        </div>
        {/* 
          <FilterButton>Камеры</FilterButton>
          <FilterButton>Домофоны</FilterButton> */}
      {/* <Typography color="textSecondary" style={{ alignSelf: 'center' }}>
          Вы можете создать свою группировку, выбрав устройства и объединив их в
          группу.
        </Typography>
      </FiltersWrapper> */}{' '}
      <DevicesToolbar toggleReassignModal={toggleReassignModal} />
      <div style={{ position: 'relative' }}>
        {pending && (
          <StyledContainer>
            <Loader />
          </StyledContainer>
        )}
        <List>
          {devices
            ? devices.map((d) => <Device {...d} key={d.uuid} />)
            : [...Array(limit).keys()].map((_, index) => (
                <DevicePlaceholder key={index} />
              ))}
          {devices?.length === 0 && (
            <Typography variant="h5">Нет устройств</Typography>
          )}
        </List>
        <ReassignIntercomModal
          onClose={toggleReassignModal}
          open={reassignModal}
        />
      </div>
      <DevicesBottombar />
    </>
  )
}
