import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit'
import API from 'utils/api'
import { buildUrl, clamp } from 'utils/helpers'
import config from 'config'
import { REASSIGN_DEVICE_SUCCESS } from 'store/companies/types'

export const fetchDevices = createAsyncThunk(
  'devices/fetchList',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const {
      search,
      is_installed,
      is_online,
      device_type,
      locality_uuid,
      service_partner_uuid,
      order = 'desc',
      page = 1,
      limit = 25,
      company_uuid,
    } = params
    try {
      const payload = await API.request(
        buildUrl('/devices', params),
        {
          dispatch,
        },
        `${config.redirectUri}/api/v2`,
      )
      return payload
    } catch {
      return rejectWithValue()
    }
  },
)

// reducers
export const devicesSlice = createSlice({
  name: 'devices',
  initialState: {
    current: null,
    list: null,
    pages: null,
    total: null,
    totalSum: null,
    page: 1,
    limit: 25,
    selected: {},
    search: '',
    pending: false,
  },
  reducers: {
    toggleSelect: (state, { payload: device }) => {
      if (device.uuid in state.selected) {
        delete state.selected[device.uuid]
      } else {
        state.selected[device.uuid] = device
      }
    },
    toggleAllDevices: (state) => {
      // add pages

      if (Object.keys(state.selected).length > 0) {
        state.selected = {}
      } else {
        state.selected = state.list.reduce(
          (acc, cur) => ({ ...acc, [cur.uuid]: cur }),
          state.selected,
        )
      }
    },
    clearDevices: (state) => {
      state.list = null
      state.pages = null
      state.total = null
      state.totalSum = null
      state.page = 1
    },
    incPage: (state) => {
      state.page = clamp(state.page + 1, 1, state.pages)
    },
    decPage: (state) => {
      state.page = clamp(state.page - 1, 1, state.pages)
    },
    setSearch: (state, { payload }) => {
      state.search = payload
    },
    clearSelected: (state) => {
      state.selected = {}
    },
  },
  extraReducers: {
    [fetchDevices.pending]: (state) => {
      state.pending = true
    },
    [fetchDevices.fulfilled]: (state, { payload }) => {
      state.list = payload.devices
      state.pages = payload.meta.total_pages
      state.total = payload.meta.total
      state.totalSum = payload.meta.total_sum
      state.pending = false
    },
    [REASSIGN_DEVICE_SUCCESS]: (state, { payload }) => {
      state.list = state.list.filter(
        (device) => !payload.devices.includes(device.uuid),
      )
      state.pending = false
      state.selected = {}
    },
  },
})

export const {
  toggleSelect,
  toggleAllDevices,
  clearDevices,
  incPage,
  decPage,
  setSearch,
} = devicesSlice.actions

const selectSelectedDevices = (state) => state.devices.selected
export const selectNumSelected = createSelector(
  [selectSelectedDevices],
  (selected) => {
    return Object.keys(selected).length
  },
)
export const selectTotalServicesSum = createSelector(
  [selectSelectedDevices],
  (selected) =>
    Object.values(selected).reduce((acc, cur) => (acc += cur.services_sum), 0),
)

export default devicesSlice.reducer
