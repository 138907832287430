export const FETCH_SERVICES_REQUEST = 'services/FETCH_SERVICES_REQUEST'
export const FETCH_SERVICES_SUCCESS = 'services/FETCH_SERVICES_SUCCESS'
export const FETCH_SERVICES_FAILURE = 'services/FETCH_SERVICES_FAILURE'

export const ACTIVATE_SERVICE_REQUEST = 'services/ACTIVATE_SERVICE_REQUEST'
export const ACTIVATE_SERVICE_SUCCESS = 'services/ACTIVATE_SERVICE_SUCCESS'
export const ACTIVATE_SERVICE_FAILURE = 'services/ACTIVATE_SERVICE_FAILURE'

export const DEACTIVATE_SERVICE_REQUEST = 'services/DEACTIVATE_SERVICE_REQUEST'
export const DEACTIVATE_SERVICE_SUCCESS = 'services/DEACTIVATE_SERVICE_SUCCESS'
export const DEACTIVATE_SERVICE_FAILURE = 'services/DEACTIVATE_SERVICE_FAILURE'
