import React, { useCallback } from 'react'
import styled from 'styled-components'
import { formatDeviceId } from 'sputnik-ui'
import { ListItem, ListItemIcon, ListItemText, Checkbox, Tooltip } from '@material-ui/core'
import { Power, PowerOff, FileCopy } from '@material-ui/icons'
import cam from './cam.png'
import intercom from './sputnik.png'
import { toggleSelect } from './devicesSlice'
import { useSelector, useDispatch } from 'react-redux'
import useHover from 'hooks/useHover'
import { copyToClipboard } from 'utils/helpers'
import { showInfoMessage } from 'store/ui/actions'
import { Archive3, Archive5, Archive7, Archive14, Archive30, IconColors } from 'sputnik-icons'
import { ReactComponent as FaceRec } from './face_rec.svg'
import { DeviceModel } from 'sputnik-ui'

const OnlineIcon = styled(Power)`
  color: ${(p) => p.theme.palette.success[500]};
  font-size: 0.825rem;
  margin-left: 0.325rem;
`
const OfflineIcon = styled(PowerOff)`
  color: ${(p) => p.theme.palette.error[500]};
  font-size: 0.825rem;
  margin-left: 0.325rem;
`
const styles = {
  icon: {
    marginRight: '.325rem',
    width: 14,
    height: 14,
  },
}
const serviceIcons = {
  archive_3: <Archive3 color={IconColors.blue} style={styles.icon} />,
  archive_5: <Archive5 color={IconColors.blue} style={styles.icon} />,
  archive_7: <Archive7 color={IconColors.blue} style={styles.icon} />,
  archive_14: <Archive14 color={IconColors.blue} style={styles.icon} />,
  archive_30: <Archive30 color={IconColors.blue} style={styles.icon} />,
  face_rec: <FaceRec style={styles.icon} />,
}

export default function Device({
  address,
  serial_number,
  services_count,
  services_sum,
  is_online,
  motherboard_id,
  uuid,
  type,
  short_serial,
  service_partner,
  services,
  model,
}) {
  const dispatch = useDispatch()
  const selected = useSelector(({ devices }) => uuid in devices.selected)
  const handleToggle = useCallback(
    (e) => {
      e.stopPropagation()
      dispatch(toggleSelect({ uuid, services_sum }))
    },
    [dispatch, services_sum, uuid]
  )

  const { hovered, bind } = useHover()

  return (
    <ListItem button style={{ paddingLeft: 0 }} onClick={handleToggle} {...bind}>
      <ListItemIcon style={{ minWidth: 'unset' }}>
        <Checkbox color="primary" checked={selected} />
      </ListItemIcon>
      <ListItemIcon>
        <img
          src={type === 'camera' ? cam : intercom}
          alt="device"
          style={{
            height: 32,
            width: 44,
            objectFit: 'contain',
            objectPosition: 'center',
          }}
        ></img>
      </ListItemIcon>

      <ListItemText
        primary={
          <>
            <div style={{ display: 'flex' }}>
              {type === 'camera' ? (
                serial_number
              ) : (
                <>
                  {formatDeviceId(serial_number)}
                  &nbsp;
                  {`/ ${short_serial}`}
                  &nbsp;
                  {model && <DeviceModel model={model} />}
                </>
              )}
              {hovered && (
                <FileCopy
                  style={{ width: 14, height: 14, marginLeft: 8 }}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    copyToClipboard(serial_number)
                    dispatch(
                      showInfoMessage(`Номер ${serial_number} был скопирован в буфер обмена`)
                    )
                  }}
                />
              )}
            </div>
          </>
        }
        secondary={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {typeof is_online === 'boolean' && (
              <Tooltip title={is_online ? 'в сети' : 'не в сети'}>
                {is_online ? (
                  <OnlineIcon
                    style={{
                      fontSize: '0.625rem',
                      marginLeft: '.325rem',
                    }}
                  />
                ) : (
                  <OfflineIcon
                    style={{
                      fontSize: '0.625rem',
                      marginLeft: '.325rem',
                    }}
                  />
                )}
              </Tooltip>
            )}
            &nbsp;
            {services?.map((identifier, index) => {
              const Component = serviceIcons[identifier]

              return <div key={index}>{Component}</div>
            })}
            {address || 'Не установлена'}
            {service_partner ? ` · Обслуживается «${service_partner}»` : ''}
          </div>
        }
      ></ListItemText>
    </ListItem>
  )
}
