import * as types from './types'

export const clearMessages = () => ({
  type: types.CLEAR_MESSAGES,
})

export const showInfoMessage = (payload) => ({
  type: types.SHOW_INFO_MESSAGE,
  payload,
})
