import React from 'react'
import { connect } from 'react-redux'
import { LinkDeviceForm, LinkDeviceTemplate } from 'components'
import * as Yup from 'yup'
import { Toast } from 'sputnik-ui'

import {
  fetchCompanies,
  fetchDevices,
  linkDeviceToCompany,
} from 'store/operations'
import { clearMessages } from 'store/actions'

const initialState = {
  fields: {
    devices: [],
    company: '',
  },
  errors: {},
  createCompanyModalOpen: false,
  devicesQuery: '',
}

class LinkDevice extends React.Component {
  constructor(props) {
    super(props)

    this.state = initialState
  }

  componentDidMount = () => {
    this.props.dispatch(fetchCompanies())
  }

  resetState = () => this.setState(initialState)

  handleSubmit = async e => {
    e.preventDefault()
    try {
      await this.validate()

      const {
        fields: { company, devices },
      } = this.state

      const devicesUuids = devices.map(d => d.value)
      const companyUuid = company.value

      this.props
        .dispatch(
          linkDeviceToCompany({
            company: companyUuid,
            devices: devicesUuids,
          }),
        )
        .then(this.resetState)
    } catch (err) {
      console.log(err)
    }
  }

  handleSelect = field => choice => {
    console.log(choice)
    this.setState({
      fields: {
        ...this.state.fields,
        [field]: choice,
      },
      errors: {
        ...this.state.errors,
        [field]: null,
      },
    })
  }

  handleBlur = e => this.validate(e.target.name)

  validate = () => {
    const schema = Yup.object().shape({
      devices: Yup.array().required('выберите устройство'),
      company: Yup.string().required('выберите компанию'),
    })

    return schema
      .validate(this.state.fields)
      .then(res => Promise.resolve(res))
      .catch(err => {
        this.setState({
          errors: {
            ...this.state.errors,
            [err.path]: err.message,
          },
        })
        return Promise.reject(err)
      })
  }

  mapOptions = options =>
    options
      ? options.map(o => ({
          label: o.name,
          value: o.uuid,
          search: o.name.slice(1),
        }))
      : []

  devicesPromise = q =>
    new Promise((resolve, reject) =>
      this.props
        .dispatch(fetchDevices({ q }))
        .then(devices => {
          const options = this.mapOptions(devices)
          resolve(options)
        })
        .catch(reject),
    )

  openCreateCompanyModal = () =>
    this.setState({
      createCompanyModalOpen: true,
    })

  closeCreateCompanyModal = () =>
    this.setState({
      createCompanyModalOpen: false,
    })

  handleDevicesSearch = e => {
    console.log(e)
  }

  render() {
    return (
      <LinkDeviceTemplate>
        <LinkDeviceForm
          devicesPromise={this.devicesPromise}
          mapOptions={this.mapOptions}
          handleSelect={this.handleSelect}
          selected={this.state.fields}
          openCreateCompanyModal={this.openCreateCompanyModal}
          handleSubmit={this.handleSubmit}
          devicesCount={this.props.devicesCount}
          {...this.props}
          {...this.state}
        />

        <Toast
          variant="error"
          handleClose={() => this.props.dispatch(clearMessages())}
          open={!!this.props.error}
        >
          {this.props.error}
        </Toast>
        <Toast
          variant="success"
          handleClose={() => this.props.dispatch(clearMessages())}
          open={!!this.props.msg}
        >
          {this.props.msg}
        </Toast>
      </LinkDeviceTemplate>
    )
  }
}

const mapStateToProps = ({ companies, devices }) => ({
  companies: companies.list,
  devices: devices.list,
  devicesCount: devices.count,
  error: companies.error || devices.error,
  msg: companies.msg,
})

export default connect(mapStateToProps)(LinkDevice)
