//@flow
import * as actions from './actions'
import Api from 'utils/api'

export const fetchServices = () => async (dispatch: Function) => {
  dispatch(actions.fetchServicesRequest())
  try {
    const active = await Api.request(`/services?active=true`)
    const inactive = await Api.request(`/services?active=false`)

    dispatch(actions.fetchServicesSuccess({ active, inactive }))
  } catch (error) {
    dispatch(
      actions.fetchServicesFailure(
        'При загрузке списка услуг произошла ошибка',
      ),
    )
  }
}

export const activateService = (serviceName: string, device?: string) => async (
  dispatch: Function,
) => {
  dispatch(actions.activateServiceRequest())
  try {
    if (device) {
      await Api.request(`/partners/devices/${device}/activate_service`, {
        method: 'POST',
        body: JSON.stringify({ service: serviceName }),
      })
    } else {
      await Api.request(`/services/${serviceName}/activate`, { method: 'POST' })
    }

    const msg = `Услуга был успешно подключена`
    dispatch(actions.activateServiceSuccess(msg))
    fetchServices()
    return Promise.resolve(msg)
  } catch (error) {
    const r = await error.json()
    dispatch(actions.activateServiceFailure(r.error))
    return Promise.reject(r.error)
  }
}

export const deactivateService = (
  serviceName: string,
  device?: string,
) => async (dispatch: Function) => {
  dispatch(actions.deactivateServiceRequest())
  try {
    await Api.request(``)

    const msg = `Услуга была успешно отключена`
    dispatch(actions.deactivateServiceSuccess(msg))
    fetchServices()

    return Promise.resolve(msg)
  } catch (error) {
    const r = await error.json()
    dispatch(actions.deactivateServiceFailure(r.error))
    return Promise.reject(r.error)
  }
}
