import Api from 'utils/api'
import * as actions from './actions'
import config from 'config'
import { buildUrl, buildUrlQuery } from 'utils/helpers'

export const fetchCompanies =
  (q = '') =>
  async (dispatch) => {
    dispatch(actions.fetchCompaniesRequest())
    try {
      const companies = await Api.request(
        `/companies?q=${q}&limit=50`,
        {},
        `${config.redirectUri}/api/v2`
      )
      dispatch(actions.fetchCompaniesSuccess(companies))
      return Promise.resolve(companies)
    } catch (error) {
      // const msg = await error.json()
      console.log(error)
      dispatch(actions.fetchCompaniesFailure())
      return Promise.reject(error)
    }
  }

export const createCompany =
  ({ email, company_name, company_tin }) =>
  async (dispatch) => {
    dispatch(actions.createCompanyRequest())
    try {
      const { company_uuid } = await Api.request('/companies', {
        method: 'POST',
        body: JSON.stringify({ email, company_name, company_tin }),
      })
      dispatch(actions.createCompanySuccess())
      return Promise.resolve(company_uuid)
    } catch (error) {
      const r = await error.json()
      dispatch(actions.createCompanyFailure(r.error))
      return Promise.reject(r.error)
    }
  }

const parseServiceErrors = async (err) => {
  if (err.status === 207) {
    const body = await err.json()
    return body
      .map((item) => {
        return item.error === true ? item.message : ''
      })
      .join(' ')
  } else {
    const { message } = await err.json()
    return message
  }
}

export const linkDeviceToCompany =
  ({ company, devices }) =>
  async (dispatch) => {
    console.log(company, devices)
    dispatch(actions.linkDeviceToCompanyRequest())
    try {
      await Api.request('/bindings', {
        method: 'POST',
        body: JSON.stringify({ devices, company_uuid: company }),
      })
      dispatch(
        actions.linkDeviceToCompanySuccess(
          `${
            devices.length > 1 ? 'Устройства были' : 'Устройство было'
          }  успешно привязано к компании`
        )
      )
    } catch (error) {
      const r = await parseServiceErrors(error)
      dispatch(actions.linkDeviceToCompanyFailure(r.error))
    }
  }

export const fetchCompanyInvoices = (companyUuid) => async (dispatch) => {
  dispatch(actions.fetchCompanyInvoicesRequest())
  try {
    const invoices = await Api.request(`/companies/${companyUuid}/invoices`)
    dispatch(actions.fetchCompanyInvoicesSuccess(invoices))
  } catch (error) {
    const r = await error.json()
    dispatch(actions.fetchCompanyInvoicesFailure(r.error))
  }
}

export const approveInvoice = (invoiceUuid) => async (dispatch) => {
  dispatch(actions.approveInvoiceRequest())
  try {
    await Api.request(`/invoices/${invoiceUuid}/approve`)
    dispatch(actions.approveInvoiceSuccess('Операция была успешно одобрена'))
    return Promise.resolve()
  } catch (error) {
    const r = await error.json()
    dispatch(actions.approveInvoiceFailure(r.error))
    return Promise.reject()
  }
}

export const fetchCompanyDevices =
  ({ companyUuid, page, items, q }) =>
  async (dispatch) => {
    dispatch(actions.fetchCompanyDevicesRequest())
    try {
      const setParams = () => {
        const params = []

        if (page) params.push(`page=${page + 1}`)
        if (items) params.push(`items=${items}`)
        if (q) params.push(`q=${q}`)

        return params.join('&')
      }
      const {
        intercoms: devices,
        total_pages: totalPages,
        pending,
      } = await Api.request(`/companies/${companyUuid}/intercoms?${setParams()}`)
      dispatch(actions.fetchCompanyDevicesSuccess({ devices, totalPages, pending }))
    } catch (error) {
      const r = await error.json()
      dispatch(actions.fetchCompanyDevicesFailure(r.error))
    }
  }

export const reassignDevice =
  ({ companyName, devices, companyUuid }) =>
  async (dispatch) => {
    console.log(devices)
    dispatch(actions.reassignDeviceRequest())
    try {
      await Api.request(`/bindings/reassign`, {
        method: 'PATCH',
        body: JSON.stringify({
          company_uuid: companyUuid,
          devices,
        }),
      })
      dispatch(
        actions.reassignDeviceSuccess({
          msg: `Устройства были успешно привязаны к компании ${companyName}`,
          devices,
        })
      )
      return Promise.resolve()
    } catch (error) {
      const { message } = await error.json()
      dispatch(actions.reassignDeviceFailure(message))
      return Promise.reject()
    }
  }

export const fetchCompanyInfo =
  ({ companyUuid }) =>
  async (dispatch) => {
    dispatch(actions.fetchCompanyInfoRequest())
    try {
      const info = await Api.request(`/companies/${companyUuid}`)
      dispatch(actions.fetchCompanyInfoSuccess(info))
      return Promise.resolve()
    } catch (error) {
      dispatch(actions.fetchCompanyInfoFailure())
      return Promise.reject()
    }
  }

export const expenseBilling =
  ({ companyUuid, amount, comment }) =>
  async (dispatch) => {
    dispatch(actions.sendMoneyRequest())
    try {
      await Api.request(`/companies/${companyUuid}/expense_billing_account`, {
        method: 'POST',
        body: JSON.stringify({
          amount,
          comment,
        }),
      })
      dispatch(actions.sendMoneySuccess('Деньги были успешно списаны'))
      return Promise.resolve()
    } catch (error) {
      const { message } = await error.json()
      dispatch(actions.sendMoneyFailure(message))
      return Promise.reject()
    }
  }

export const sendMoney =
  ({ companyUuid, amount, comment }) =>
  async (dispatch) => {
    dispatch(actions.sendMoneyRequest())
    try {
      await Api.request(`/companies/${companyUuid}/load_billing_account`, {
        method: 'POST',
        body: JSON.stringify({
          amount,
          comment,
        }),
      })
      dispatch(actions.sendMoneySuccess('Деньги были успешно отправлены'))
      return Promise.resolve()
    } catch (error) {
      const { message } = await error.json()
      dispatch(actions.sendMoneyFailure(message))
      return Promise.reject()
    }
  }

const crossMessage = 'Общая информация обновлена'
export const changeCompanyName =
  ({ companyUuid, companyName }) =>
  async (dispatch) => {
    dispatch(actions.changeCompanyNameRequest())
    try {
      await Api.request(
        `/companies/${companyUuid}`,
        {
          method: 'PATCH',
          body: JSON.stringify({ company_name: companyName }),
        },
        `${config.redirectUri}/api/v2`
      )
      //  dispatch(actions.changeCompanyNameSuccess('Имя компании обновлено'))
      dispatch(actions.changeCompanyNameSuccess(crossMessage))
      return Promise.resolve()
    } catch (error) {
      const { message } = await error.json()
      dispatch(actions.changeCompanyNameFailure(message))
      return Promise.reject()
    }
  }

export const changeDeliveryAddress =
  ({ companyUuid, deliveryAddress }) =>
  async (dispatch) => {
    dispatch(actions.changeDeliveryAddressRequest())
    try {
      await Api.request(`/companies/${companyUuid}?delivery_address=${deliveryAddress}`, {
        method: 'PATCH',
      })
      // dispatch(
      //   actions.changeDeliveryAddressSuccess('Адрес доставки был обновлен'),
      // )
      dispatch(actions.changeCompanyNameSuccess(crossMessage))
      return Promise.resolve()
    } catch (error) {
      const { message } = await error.json()
      dispatch(actions.changeDeliveryAddressFailure(message))
      return Promise.reject()
    }
  }

export const changeCompanyBillingInfo =
  ({ companyUuid, params }) =>
  async (dispatch) => {
    // dispatch(actions.changeCompanyBillingInfoRequest())
    try {
      await Api.request(`/companies/${companyUuid}/billing_account`, {
        method: 'PATCH',
        body: JSON.stringify(params),
      })
      dispatch(actions.changeDeliveryAddressSuccess('Информация о компании была обновлена'))
      return Promise.resolve()
    } catch (error) {
      const { message } = await error.json()
      dispatch(actions.changeDeliveryAddressFailure(message))
      return Promise.reject()
    }
  }

export const connectCompanyToService =
  ({ companyUuid, service }) =>
  async (dispatch) => {
    dispatch(actions.postCompanyServiceRequest())

    const params = {
      customer_type: 'company',
      customer_uuid: companyUuid,
      subject_type: 'company',
      subject_uuid: companyUuid,
      service: service,
    }

    try {
      const res = await Api.request(`/services`, {
        method: 'POST',
        body: JSON.stringify(params),
      })
      dispatch(actions.postCompanyServiceSuccess(res?.message))
      return Promise.resolve()
    } catch (err) {
      const { error } = await err.json()
      dispatch(actions.postCompanyServiceFailure(error))
      return Promise.reject()
    }
  }

export const getCompanyService = (companyUuid) => async (dispatch) => {
  const params = {
    subject_uuid: companyUuid,
    state: 'activated',
  }

  dispatch(actions.getCompanyServiceRequest())
  try {
    const payload = await Api.request(buildUrl(`/services`, params))
    dispatch(actions.getCompanyServiceSuccess(payload))
    return Promise.resolve()
  } catch (err) {
    const { error } = await err.json()
    dispatch(actions.getCompanyServiceSuccess(error))
    return Promise.reject()
  }
}

export const deleteCompanyService = (service) => async (dispatch) => {
  dispatch(actions.deleteCompanyServiceRequest())
  const params = {
    customer_uuid: service.customer_uuid,
    service: service.service_config_identifier,
  }
  try {
    const res = await Api.request(`/services/${service.uuid}`, {
      method: 'DELETE',
      body: JSON.stringify(params),
    })
    dispatch(actions.deleteCompanyServiceSuccess(res?.message))
    return Promise.resolve()
  } catch (err) {
    const { error } = await err.json()
    dispatch(actions.deleteCompanyServiceFailure(error))
    return Promise.reject()
  }
}

export const getServicesCatalog = (subjectType) => async (dispatch) => {
  // subject_type: company, gate, house, entry, flat, camera, intercom
  const params = {
    subject_type: subjectType,
  }

  dispatch(actions.getServicesCatalogRequest())
  try {
    const payload = await Api.request(buildUrl(`/services/catalog`, params))
    dispatch(actions.getServicesCatalogSuccess(payload))
    return Promise.resolve()
  } catch (err) {
    const { error } = await err.json()
    dispatch(actions.getServicesCatalogFailure(error))
    return Promise.reject()
  }
}
