import { ListItemIcon } from '@material-ui/core'
import { ListItem } from '@material-ui/core'
import React from 'react'
import { ListItemText } from '@material-ui/core'
import { Typography } from '@material-ui/core'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { AttachMoney } from '@material-ui/icons'

import { Archive3, Archive5, Archive7, Archive14, Archive30, IconColors } from 'sputnik-icons'
import { ReactComponent as FaceRec } from './face_rec.svg'
import { ReactComponent as Refund } from './refund.svg'
import { ReactComponent as Debit } from './debit_bonus.svg'
import { ReactComponent as AccrualBonus } from './accrual_bonus.svg'
import { ReactComponent as WithdrawalMoney } from './withdrawal_money.svg'

const icon = { width: 24, height: 24 }

const serviceIcons = {
  archive_3: <Archive3 color={IconColors.blue} style={icon} />,
  archive_5: <Archive5 color={IconColors.blue} style={icon} />,
  archive_7: <Archive7 color={IconColors.blue} style={icon} />,
  archive_14: <Archive14 color={IconColors.blue} style={icon} />,
  archive_30: <Archive30 color={IconColors.blue} style={icon} />,
  face_rec: <FaceRec style={icon} />,
  refund: <Refund style={icon} />,
  debit_bonus: <Debit style={icon} />,
  accrual_bonus: <AccrualBonus style={icon} />,
  withdrawal_money: <WithdrawalMoney style={icon} />,
  partner_program: <AttachMoney style={icon} />,
}

const Price = styled(Typography)`
  && {
    color: ${(p) => (p.negative ? p.theme.palette.error[500] : p.theme.palette.success[500])};
  }
`

export default function Transaction({
  title,
  identifier,
  total_cost,
  type,
  service_cost,
  services_count,
  ...props
}) {
  const { t } = useTranslation(['billing', 'products'])
  const IconComponent = serviceIcons[identifier] || <AttachMoney style={icon} />
  const isPartnerProgramm = identifier === 'partner_program'
  // identifier ПАРТНЕРСКОЙ ПРОГРАММЫ ЧЕК partner_program
  return (
    <ListItem>
      <ListItemIcon style={{ minWidth: 48 }}>{IconComponent}</ListItemIcon>
      <ListItemText
        primary={
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <span>{title}</span>
            <Price negative={type === 'payment'} style={{ fontSize: 'inherit' }}>
              {total_cost}
            </Price>
          </div>
        }
        secondary={
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>{t(`billing:transaction.${type}`)}</span>
            <span>
              {service_cost} x{' '}
              {isPartnerProgramm ? (
                t('products:entries.all')
              ) : (
                <>
                  {services_count || 1} {t('products:units.device', { count: services_count })}
                </>
              )}
            </span>
          </div>
        }
        primaryTypographyProps={{
          style: { fontSize: '1.2rem' },
        }}
        // disableTypography
      ></ListItemText>
    </ListItem>
  )
}
