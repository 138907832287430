//@flow

import * as types from './types'
import type { Action, Company } from 'globalTypes'

type State = {
  +isLoggedIn: boolean,
  +error: ?string,
  +company: ?Company,
}

const initialState: State = {
  isLoggedIn: false,
  error: null,
  company: null,
}

export default function auth(
  state: State = initialState,
  { type, payload }: Action,
) {
  switch (type) {
    case types.SIGN_IN:
      return { ...state, isLoggedIn: true, error: null, user: payload.user }
    case types.SIGN_IN_ERROR:
      return { ...state }
    case types.LOG_OUT_SUCCESS:
      return { ...state, isLoggedIn: false, error: null, user: null }
    case types.FETCH_USER_COMPANY_SUCCESS:
      return { ...state, company: payload.company }
    default:
      return state
  }
}
