import React, { useCallback } from 'react'
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Typography,
  ListItemSecondaryAction,
  Tooltip,
} from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import {
  toggleAllDevices,
  selectNumSelected,
  selectTotalServicesSum,
} from './devicesSlice'
import { IconButton } from '@material-ui/core'
import { GroupWork } from '@material-ui/icons'
import { CloudDownload, SubdirectoryArrowRight } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { Container } from 'sputnik-ui'

const Body = styled(List)`
  && {
    background-color: ${(p) =>
      p.active ? p.theme.palette.other.green : 'rgba(255, 255, 255, 0.4)'};
    z-index: 3;
    padding: 0;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 13px 20px -24px;
    backdrop-filter: blur(20px) saturate(180%);
    min-height: 66px;
  }
`

export default function DevicesToolbar({ toggleReassignModal, ...props }) {
  const dispatch = useDispatch()

  const devices = useSelector(({ devices }) => devices.devices)
  const total = useSelector(({ devices }) => devices.total)
  const selected = useSelector(({ devices }) => devices.selected)
  const numSelected = useSelector(selectNumSelected)
  const totalServicesSum = useSelector(selectTotalServicesSum)

  const handleSelectAll = useCallback(() => {
    dispatch(toggleAllDevices())
  }, [dispatch])

  const { t } = useTranslation(['service'])

  return (
    <Body active={numSelected > 0} style={{ position: 'sticky', top: 0 }}>
      <ListItem style={{ paddingLeft: 0 }}>
        <ListItemIcon style={{ minWidth: 'unset' }}>
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0}
            checked={numSelected === total}
            onChange={handleSelectAll}
            color="primary"
          />
        </ListItemIcon>

        {numSelected > 0 ? (
          <>
            <Typography display="inline">
              Выбрано {numSelected} устройств:
            </Typography>

            <Tooltip title={t('service:actions.reassign')}>
              <IconButton
                aria-label={t('service:actions.reassign')}
                onClick={toggleReassignModal}
              >
                <SubdirectoryArrowRight />
              </IconButton>
            </Tooltip>
          </>
        ) : (
          <ListItemText
            primary={total ? `${total} устройств` : ''}
          ></ListItemText>
        )}
        {/* <ListItemSecondaryAction>
          {numSelected > 0 ? (
            <Typography align="right" style={{ fontWeight: '500' }}>
              {totalServicesSum} ₽ в месяц
            </Typography>
          ) : (
            <Typography align="right" style={{ fontWeight: '500' }}>
              стоимость услуг
            </Typography>
          )}
        </ListItemSecondaryAction> */}
      </ListItem>
    </Body>
  )
}
