//@flow
//$FlowFixMe
import React, { useEffect } from 'react'

import { Navbar, Toast, Intercom, Tabs } from 'sputnik-ui'
import { connect } from 'react-redux'
import AOS from 'aos'
import { Switch, Route, Link, withRouter, Redirect } from 'react-router-dom'
import { AppTemplate, CompaniesPage } from 'components'
import requireAuth from 'utils/requireAuth'
import { Tab } from '@material-ui/core'

import type { Location } from 'react-router-dom'

import { clearMessages } from 'store/actions'
import { fetchUserCompany } from 'store/operations'

const tabs = [
  {
    label: 'Мои компании',
    to: '/companies',
  },
]

const re = /\/[^/]*/g // until 2nd/third occurence of /

function App({ location, dispatch, msg, error, clearMessages, info }: Props) {
  const regexMatch = location.pathname.match(re)
  const namespace = regexMatch && regexMatch[0]

  useEffect(() => {
    AOS.init({
      duration: 600,
    })
    dispatch(fetchUserCompany())
  }, [dispatch])
  console.log('start test') // TODO remove
  return (
    <AppTemplate
      header={
        <Navbar logo brand app size="normal" user>
          {/* <Tabs
            value={namespace}
            
          >
            {tabs.map(tab => (
              <Tab
                disableRipple
                label={tab.label}
                value={tab.to}
                to={tab.to}
                key={tab.to}
                component={Link}
              />
            ))}
          </Tabs> */}
        </Navbar>
      }
    >
      <Switch>
        <Route path="/companies" component={CompaniesPage} />
        <Redirect to="/companies" />
      </Switch>

      <Intercom />
      <Toast variant="info" open={!!info} handleClose={clearMessages}>
        {info}
      </Toast>
      <Toast variant="success" open={!!msg} handleClose={clearMessages}>
        {msg}
      </Toast>
      <Toast variant="error" open={!!error} handleClose={clearMessages}>
        {error}
      </Toast>
    </AppTemplate>
  )
}

type ReduxProps = {
  msg: ?string,
  error: ?string,
}

type Props = ReduxProps & {
  dispatch: Function,
  location: Location,
  clearMessages: Function,
}

const mapStateToProps = ({ companies, devices, tracks, ui }): ReduxProps => ({
  msg: companies.msg || devices.msg || tracks.msg,
  error: companies.error || devices.error || tracks.error,
  info: ui.info,
})

export default withRouter(requireAuth(connect(mapStateToProps, { clearMessages })(App)))
