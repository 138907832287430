import React from 'react'
import { TransactionsTable } from 'components'
import { connect } from 'react-redux'
import { CircularProgress } from '@material-ui/core'
import { Wrapper } from 'sputnik-ui'
import styled from 'styled-components'
import { approveInvoice, fetchCompanyInvoices } from 'store/operations'

const StyledWrapper = styled(Wrapper)`
  && {
    background: ${p => p.theme.palette.white['500']};

    flex: 1;
    overflow-x: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`

const Invoices = ({ dispatch, invoices, selectedCompany }) => {
  const onApprove = invoiceUuid => () => {
    dispatch(approveInvoice(invoiceUuid)).then(() => {
      dispatch(fetchCompanyInvoices(selectedCompany.value))
    })
  }
  return invoices ? (
    <StyledWrapper data-aos="zoom-out">
      <TransactionsTable transactions={invoices} onApprove={onApprove} />
    </StyledWrapper>
  ) : (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 250,
        width: '100%',
        background: 'white',
      }}
    >
      <CircularProgress />
    </div>
  )
}

const mapStateToProps = ({ companies }) => ({
  invoices: companies.invoices,
  selectedCompany: companies.selected,
})

export default connect(mapStateToProps)(Invoices)
