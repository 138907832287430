import { ConfirmModal } from 'sputnik-ui'
import React from 'react'
import { Button } from 'sputnik-ui'
import { useDispatch } from 'react-redux'
import { deleteCompanyService, getCompanyService } from 'store/companies/operations'
import { useSelector } from 'react-redux'

export const CloseServiceAccessModal = ({ open, activeService, onClose }) => {
  const companyUuid = useSelector(({ companies }) => companies.about?.uuid)
  const dispatch = useDispatch()
  const servicesCatalog = useSelector(({ companies }) => companies.servicesCatalog)
  const isServicesPending = useSelector(({ companies }) => companies.services?.pending)
  const handleConfirm = async () => {
    await dispatch(deleteCompanyService(activeService))
    await dispatch(getCompanyService(companyUuid))
    onClose()
  }

  const serviceLabel = servicesCatalog?.find(
    (service) => service.group === activeService?.service_config_identifier
  )

  return (
    <ConfirmModal
      onClose={onClose}
      open={open}
      title="Отключение"
      label={`Отключить текущую услугу «${serviceLabel?.title}»?`}
      actions={
        <>
          <Button
            primary
            variant="v2"
            fullWidth
            onClick={handleConfirm}
            disabled={isServicesPending}
          >
            Подтвердить
          </Button>
          <Button tertiary variant="v2" onClick={onClose}>
            Отменить
          </Button>
        </>
      }
    />
  )
}
