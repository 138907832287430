import React from 'react'
import styled from 'styled-components'
import propTypes from 'prop-types'
import { Button, Select } from 'sputnik-ui'

import { Typography } from '@material-ui/core'

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  width: 100%;
  margin: 0 2rem;

  @media screen and (min-width: 768px) {
    max-width: 550px;
    margin: 0;
  }
`

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1.625rem;
  width: 100%;

  button:first-child {
    margin-right: 0.625rem;
  }
`

export default class LinkDeviceForm extends React.Component {
  static propTypes = {
    mapOptions: propTypes.func.isRequired,
    handleSelect: propTypes.func.isRequired,
    openCreateCompanyModal: propTypes.func.isRequired,
    handleSubmit: propTypes.func.isRequired,
    fields: propTypes.object.isRequired,
    errors: propTypes.object.isRequired,
    selected: propTypes.object.isRequired,
    devicesCount: propTypes.number,
  }

  render() {
    const { fields, errors } = this.props

    return (
      <Form onSubmit={this.props.handleSubmit}>
        <div style={styles.select}>
          <Select
            name="devices"
            handleSelect={this.props.handleSelect('devices')}
            selected={fields.devices}
            loadOptions={this.props.devicesPromise}
            placeholder="Код устройства"
            async
            defaultOptions
          />
          {this.props.devicesCount && fields.devices && (
            <Typography variant="caption">
              {`Осталось ${this.props.devicesCount -
                fields.devices.length} устройств`}
            </Typography>
          )}
          {!!errors.device && (
            <Typography color="error" variant="caption">
              {errors.device}
            </Typography>
          )}
        </div>

        <div style={styles.companyInputContainer}>
          <div style={styles.select}>
            <Select
              name="company"
              options={this.props.mapOptions(this.props.companies)}
              handleSelect={this.props.handleSelect('company')}
              selected={fields.company}
              placeholder="Название компании"
              isMulti={false}
            />
            {!!errors.company && (
              <Typography color="error" variant="caption">
                {errors.company}
              </Typography>
            )}
          </div>
        </div>

        <ActionsWrapper>
          <Button
            primary
            rounded
            type="submit"
            disabled={
              !fields.devices ||
              !fields.company ||
              Array.isArray(fields.company)
            }
          >
            Привязать
          </Button>
        </ActionsWrapper>
      </Form>
    )
  }
}

const styles = {
  select: {
    flex: 1,
    alignSelf: 'flex-end',
  },
  companyInputContainer: {
    display: 'flex',
    width: '100%',
    marginTop: '1.325rem',
  },
}
