import React, { useState, useEffect } from 'react'
import { Typography, Tooltip } from '@material-ui/core'
import styled from 'styled-components'
import Skeleton from 'react-loading-skeleton'
import { Toast, Loader, Wrapper, Button, useInput } from 'sputnik-ui'
import { connect } from 'react-redux'
import { changeDeliveryAddress } from 'store/operations'
import Tag from 'components/atoms/Tag'
import { Formik, Form } from 'formik'
import FormField from 'components/atoms/FormField'
import {
  changeCompanyBillingInfo,
  changeCompanyName,
} from 'store/companies/operations'
import { useSelector } from 'react-redux'

const Category = styled.div`
  margin-bottom: 1rem;
  width: 100%;
`

const Separator = styled.div`
  /* margin-bottom: 1rem; */
`

const Subsection = styled.div`
  padding: 1rem;
  flex-direction: column;
`

const Tags = styled.div`
  display: flex;
  margin-bottom: 1rem;
  & > * {
    margin-right: 1rem;
  }
`

const StyledWrapper = styled(Wrapper)`
  background: ${(p) => p.theme.palette.white['500']};

  overflow: hidden;
  flex-direction: column;
`

const ClickableTypography = ({ children, didFetchInfo, onClick }) => {
  const [lastCopied, setLastCopied] = useState(null)

  const copyValue = (value) => () => {
    if (value) {
      const el = document.createElement('textarea')
      el.value = value
      document.body && document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body && document.body.removeChild(el)
      setLastCopied(value)
    }
  }
  return (
    <>
      <Tooltip placement="bottom-start" title="Скопировать">
        <Typography
          onClick={copyValue(children)}
          style={{ cursor: 'pointer' }}
          color="textSecondary">
          {!didFetchInfo ? <Skeleton /> : children || '—'}
        </Typography>
      </Tooltip>
      <Toast
        open={!!lastCopied}
        variant="info"
        handleClose={() => setLastCopied(null)}>
        Скопировано {lastCopied}
      </Toast>
    </>
  )
}

const AboutCompany = ({ info, dispatch }) => {
  const selectedCompanyUuid = useSelector(
    ({ companies }) => companies.selected?.value,
  )

  const {
    value: deliveryAddress,
    setValue: setDeliveryAddress,
    bind: bindDeliveryAddress,
  } = useInput(info && info.delivery_address, () => setDidUpdateValues(true))

  const {
    value: companyName,
    setValue: setCompanyName,
    bind: bindCompanyName,
  } = useInput(info && info.company_name, () => setDidUpdateValues(true))

  const [didFetchInfo, setDidFetchInfo] = useState(false)

  useEffect(() => {
    if (info) {
      setDeliveryAddress(info.delivery_address)
      setCompanyName(info.company_name)
      setDidFetchInfo(true)
    }
  }, [info, setDeliveryAddress, setCompanyName])

  const [didUpdateValues, setDidUpdateValues] = useState(false)
  const handleUpdateDeliveryAddress = () => {
    dispatch(
      changeDeliveryAddress({
        deliveryAddress,
        companyUuid: selectedCompanyUuid,
      }),
    ).then(() => setDidUpdateValues(false))
  }

  const handleUpdateCompanyName = () => {
    dispatch(
      changeCompanyName({
        companyName,
        companyUuid: selectedCompanyUuid,
      }),
    ).then(() => setDidUpdateValues(false))
  }

  const handleUpdateCompany = (values) => {
    dispatch(
      changeCompanyBillingInfo({
        companyUuid: selectedCompanyUuid,
        params: values,
      }),
    )
  }

  return (
    <Formik
      initialValues={info}
      enableReinitialize
      onSubmit={handleUpdateCompany}>
      <Form>
        <Tags>
          {info &&
            info.tags &&
            info.tags.map((t) => <Tag key={t.id}>{t.name}</Tag>)}
        </Tags>
        <Typography variant="h5" gutterBottom>
          Общая информация
        </Typography>
        <StyledWrapper
          style={{ marginBottom: '1rem', flexDirection: 'column' }}>
          <Category>
            <Typography variant="subtitle2">Название компании</Typography>
            <FormField
              placeholder="Название компании"
              {...bindCompanyName}
              // fullWidth
            />
          </Category>
          <Category>
            <Typography variant="subtitle2">Intercom</Typography>
            <Typography
              component="a"
              href={info && info.intercom}
              target="_blank">
              {info && info.intercom}
            </Typography>
          </Category>
          <Category>
            <Typography variant="subtitle2">Адрес доставки</Typography>
            <FormField
              placeholder="ул. Пушкина, д. Колотушкина"
              {...bindDeliveryAddress}
              fullWidth
            />
          </Category>

          {didUpdateValues && (deliveryAddress || companyName) !== '' && (
            <Button
              rounded
              primary
              style={{ marginRight: 'auto' }}
              type="button"
              onClick={() => {
                handleUpdateDeliveryAddress()
                handleUpdateCompanyName()
              }}>
              обновить данные
            </Button>
          )}
        </StyledWrapper>

        <Typography variant="h5" gutterBottom>
          Платежная информация
        </Typography>
        <StyledWrapper
          // data-aos="zoom-out"
          style={{ flexDirection: 'column', padding: 0 }}>
          {info ? (
            <>
              <Subsection>
                <Category>
                  <Typography variant="subtitle2">Электронная почта</Typography>
                  <ClickableTypography didFetchInfo={didFetchInfo}>
                    {info.email}
                  </ClickableTypography>
                </Category>

                {/* <Category>
                <Typography variant="subtitle2">Инн</Typography>
                <ClickableTypography didFetchInfo={didFetchInfo}>
                  {info.tin}
                </ClickableTypography>
              </Category> */}

                <div style={{ display: 'flex' }}>
                  <FormField
                    name="tin"
                    label="ИНН"
                    fullWidth
                    style={{ marginRight: '1rem' }}
                  />
                  <FormField name="kpp" label="КПП" fullWidth></FormField>
                </div>
                <div style={{ display: 'flex' }}>
                  <FormField
                    name="contract_number"
                    label="Номер договора"
                    fullWidth
                    style={{ marginRight: '1rem' }}
                  />
                  <FormField name="bank" label="Банк" fullWidth />
                </div>
              </Subsection>
              <Separator />

              <Subsection
                style={{
                  paddingTop: '1rem',
                  background: '#fafafa',
                  borderTop: '2px dashed #333',
                }}>
                <div style={{ display: 'flex' }}>
                  <Category style={{ flex: 1, marginRight: '1rem' }}>
                    <Typography variant="subtitle2">Телефон</Typography>
                    <ClickableTypography didFetchInfo={didFetchInfo}>
                      {info.phone_number}
                    </ClickableTypography>
                  </Category>

                  <Category style={{ flex: 1 }}>
                    <Typography variant="subtitle2">Индекс</Typography>
                    <ClickableTypography didFetchInfo={didFetchInfo}>
                      {info.postal_code}
                    </ClickableTypography>
                  </Category>
                </div>

                <Category>
                  <Typography variant="subtitle2">Фактический адрес</Typography>
                  <ClickableTypography didFetchInfo={didFetchInfo}>
                    {info.actual_address}
                  </ClickableTypography>
                </Category>

                <Category>
                  <Typography variant="subtitle2">Юридический адрес</Typography>
                  <ClickableTypography didFetchInfo={didFetchInfo}>
                    {info.legal_address}
                  </ClickableTypography>
                </Category>

                <div style={{ display: 'flex' }}>
                  <Category style={{ flex: 1, marginRight: '1rem' }}>
                    <Typography variant="subtitle2">БИК</Typography>
                    <ClickableTypography didFetchInfo={didFetchInfo}>
                      {info.bic}
                    </ClickableTypography>
                  </Category>

                  <Category style={{ flex: 1, marginRight: '1rem' }}>
                    <Typography variant="subtitle2">Расчетный счет</Typography>
                    <ClickableTypography didFetchInfo={didFetchInfo}>
                      {info.settlement_account}
                    </ClickableTypography>
                  </Category>
                </div>

                <Button
                  rounded
                  primary
                  style={{ marginRight: 'auto' }}
                  type="submit">
                  обновить данные
                </Button>
              </Subsection>
            </>
          ) : (
            <Loader msg="Загружаем информацию о компании" />
          )}
        </StyledWrapper>
      </Form>
    </Formik>
  )
}

const mapStateToProps = ({ companies }) => ({
  info: companies.about,
  selectedCompanyUuid: companies.selected && companies.selected.value,
})

export default connect(mapStateToProps)(AboutCompany)
