import * as types from './types'

export const fetchCompaniesRequest = () => ({
  type: types.FETCH_COMPANIES_REQUEST,
})
export const fetchCompaniesSuccess = (companies) => ({
  type: types.FETCH_COMPANIES_SUCCESS,
  payload: {
    companies,
  },
})
export const fetchCompaniesFailure = (error) => ({
  type: types.FETCH_COMPANIES_FAILURE,
  payload: {
    error,
  },
})

export const createCompanyRequest = () => ({
  type: types.CREATE_COMPANY_REQUEST,
})
export const createCompanySuccess = () => ({
  type: types.CREATE_COMPANY_SUCCESS,
})
export const createCompanyFailure = (error) => ({
  type: types.CREATE_COMPANY_FAILURE,
  payload: {
    error,
  },
})

export const linkDeviceToCompanyRequest = () => ({
  type: types.LINK_DEVICE_TO_COMPANY_REQUEST,
})
export const linkDeviceToCompanySuccess = (msg) => ({
  type: types.LINK_DEVICE_TO_COMPANY_SUCCESS,
  payload: {
    msg,
  },
})
export const linkDeviceToCompanyFailure = (error) => ({
  type: types.LINK_DEVICE_TO_COMPANY_FAILURE,
  payload: {
    error,
  },
})

export const fetchCompanyInvoicesRequest = () => ({
  type: types.FETCH_INVOICES_REQUEST,
})
export const fetchCompanyInvoicesSuccess = (invoices) => ({
  type: types.FETCH_INVOICES_SUCCESS,
  payload: {
    invoices,
  },
})
export const fetchCompanyInvoicesFailure = (error) => ({
  type: types.FETCH_INVOICES_FAILURE,
  payload: {
    error,
  },
})

export const clearCompanyData = () => ({
  type: types.CLEAR_COMPANY_DATA,
})

export const approveInvoiceRequest = () => ({
  type: types.APPROVE_INVOICE_REQUEST,
})
export const approveInvoiceSuccess = (msg) => ({
  type: types.APPROVE_INVOICE_SUCCESS,
  payload: {
    msg,
  },
})
export const approveInvoiceFailure = (error) => ({
  type: types.APPROVE_INVOICE_FAILURE,
  payload: {
    error,
  },
})

export const fetchCompanyDevicesRequest = () => ({
  type: types.FETCH_COMPANY_DEVICES_REQUEST,
})
export const fetchCompanyDevicesSuccess = ({ devices, totalPages, pending }) => ({
  type: types.FETCH_COMPANY_DEVICES_SUCCESS,
  payload: {
    devices,
    totalPages,
    pending,
  },
})
export const fetchCompanyDevicesFailure = (error) => ({
  type: types.FETCH_COMPANY_DEVICES_FAILURE,
  payload: {
    error,
  },
})

export const setSelectedCompany = (company) => ({
  type: types.SET_SELECTED_COMPANY,
  payload: {
    company,
  },
})

export const reassignDeviceRequest = () => ({
  type: types.REASSIGN_DEVICE_REQUEST,
})
export const reassignDeviceSuccess = ({ msg, devices }) => ({
  type: types.REASSIGN_DEVICE_SUCCESS,
  payload: {
    msg,
    devices,
  },
})
export const reassignDeviceFailure = (error) => ({
  type: types.REASSIGN_DEVICE_FAILURE,
  payload: {
    error,
  },
})

export const fetchCompanyInfoRequest = () => ({
  type: types.FETCH_COMPANY_INFO_REQUEST,
})
export const fetchCompanyInfoSuccess = (info) => ({
  type: types.FETCH_COMPANY_INFO_SUCCESS,
  payload: {
    info,
  },
})
export const fetchCompanyInfoFailure = (error) => ({
  type: types.FETCH_COMPANY_INFO_FAILURE,
  payload: {
    error,
  },
})

// export const expenseMoneyRequest = () => ({
//     type: types.EXPENSE_MONEY_REQUEST
// })
// export const expenseMoneySuccess = (msg) => ({
//     type: types.EXPENSE_MONEY_SUCCESS,
//     payload: {
//         msg,
//       },
// })
// export const expenseMoneyFailure = (error) => ({
//     type: types.EXPENSE_MONEY_FAILURE,
//     payload: {
//         error,
//     }
// })

export const sendMoneyRequest = () => ({
  type: types.SEND_MONEY_REQUEST,
})
export const sendMoneySuccess = (msg) => ({
  type: types.SEND_MONEY_SUCCESS,
  payload: {
    msg,
  },
})
export const sendMoneyFailure = (error) => ({
  type: types.SEND_MONEY_FAILURE,
  payload: {
    error,
  },
})

export const changeDeliveryAddressRequest = () => ({
  type: types.CHANGE_DELIVERY_ADDRESS_REQUEST,
})
export const changeDeliveryAddressSuccess = (msg) => ({
  type: types.CHANGE_DELIVERY_ADDRESS_SUCCESS,
  payload: {
    msg,
  },
})
export const changeDeliveryAddressFailure = (error) => ({
  type: types.CHANGE_DELIVERY_ADDRESS_FAILURE,
  payload: {
    error,
  },
})

export const changeCompanyNameRequest = () => ({
  type: types.CHANGE_COMPANY_NAME_REQUEST,
})
export const changeCompanyNameSuccess = (msg) => ({
  type: types.CHANGE_COMPANY_NAME_SUCCESS,
  payload: {
    msg,
  },
})
export const changeCompanyNameFailure = (error) => ({
  type: types.CHANGE_COMPANY_NAME_FAILURE,
  payload: {
    error,
  },
})

// pro\developer service

export const getCompanyServiceRequest = () => ({
  type: types.COMPANY_SERVICE_REQUEST,
})
export const getCompanyServiceSuccess = (services) => ({
  type: types.COMPANY_SERVICE_SUCCESS,
  payload: services,
})
export const getCompanyServiceFailure = (error) => ({
  type: types.COMPANY_SERVICE_FAILURE,
  payload: {
    error,
  },
})

export const postCompanyServiceRequest = () => ({
  type: types.POST_COMPANY_SERVICE_REQUEST,
})
export const postCompanyServiceSuccess = (msg) => ({
  type: types.POST_COMPANY_SERVICE_SUCCESS,
  payload: {
    msg,
  },
})
export const postCompanyServiceFailure = (error) => ({
  type: types.POST_COMPANY_SERVICE_FAILURE,
  payload: {
    error,
  },
})

export const deleteCompanyServiceRequest = () => ({
  type: types.DELETE_COMPANY_SERVICE_REQUEST,
})
export const deleteCompanyServiceSuccess = (msg) => ({
  type: types.DELETE_COMPANY_SERVICE_SUCCESS,
  payload: {
    msg,
  },
})
export const deleteCompanyServiceFailure = (error) => ({
  type: types.DELETE_COMPANY_SERVICE_FAILURE,
  payload: {
    error,
  },
})

export const getServicesCatalogRequest = () => ({
  type: types.COMPANY_SERVICE_CATALOG_REQUEST,
})
export const getServicesCatalogSuccess = ({services}) => ({
  type: types.COMPANY_SERVICE_CATALOG_SUCCESS,
  payload: {
    catalog: services,
  },
})
export const getServicesCatalogFailure = (error) => ({
  type: types.COMPANY_SERVICE_CATALOG_FAILURE,
  payload: {
    error,
  },
})


