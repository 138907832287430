import Api from 'utils/api'

import * as actions from './actions'

export const fetchTracks = companyUuid => async dispatch => {
  dispatch(actions.fetchTracksRequest())
  try {
    const tracks = await Api.request(
      `/track_numbers?company_uuid=${companyUuid}`,
    )
    dispatch(actions.fetchTracksSuccess(tracks))

    return Promise.resolve(tracks)
  } catch (error) {
    const { message } = await error.json()
    dispatch(actions.fetchTracksFailure(message))
    return Promise.reject(message)
  }
}

export const addTrack = (companyUuid, track) => async dispatch => {
  dispatch(actions.addTrackRequest())
  try {
    await Api.request(`/track_numbers`, {
      method: 'POST',
      body: JSON.stringify({
        company_uuid: companyUuid,
        number: track,
      }),
    })
    dispatch(actions.addTrackSuccess())

    return Promise.resolve()
  } catch (error) {
    const { message } = await error.json()
    dispatch(actions.addTrackFailure(message))
    return Promise.reject(message)
  }
}

export const removeTrack = trackId => async dispatch => {
  dispatch(actions.removeTrackRequest())
  try {
    await Api.request(`/track_numbers/${trackId}`, {
      method: 'DELETE',
    })
    dispatch(actions.removeTrackSuccess())

    return Promise.resolve()
  } catch (error) {
    const { message } = await error.json()
    dispatch(actions.removeTrackFailure(message))
    return Promise.reject(message)
  }
}
