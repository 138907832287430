import * as actions from './actions'
import Api from 'utils/api'
import { buildUrl } from 'utils/helpers'
import config from 'config'

export const fetchTransactions =
  (uuid, year = new Date().getFullYear().toString(), page = 0, limit = 10) =>
  async (dispatch) => {
    dispatch(actions.fetchTransactionsRequest())
    try {
      const setParams = () => {
        let params = []
        if (year) params.push(`year=${year}`)
        if (page) params.push(`page=${page + 1}`)
        if (limit) params.push(`limit=${limit}`)
        return params.join('&')
      }

      const transactions = await Api.request(
        buildUrl(
          `/companies/${uuid}/billing_account/operations/days?${setParams()}`
        ),
        {
          dispatch,
        },
        `${config.baseUri}`
      )

      dispatch(actions.fetchTransactionsSuccess(transactions))
    } catch (error) {
      dispatch(
        actions.fetchTransactionsFailure(
          'Не удалось получить историю операций. Попробуйте обновить страницу.'
        )
      )
    }
  }

export const fetchStatistics = (year) => async (dispatch) => {
  dispatch(actions.fetchStatisticsRequest())
  try {
    const stats = await Api.request(
      `/billing/operations/statistics?year=${year}`
    )
    dispatch(actions.fetchStatisticsSuccess(stats))
  } catch (error) {
    dispatch(
      actions.fetchStatisticsFailure(
        'Проверьте ваше интернет соединение и попробуйте обновить страницу.'
      )
    )
  }
}

export const fetchAvailablePeriod = (uuid) => async (dispatch) => {
  dispatch(actions.fetchAvailablePeriodRequest())
  try {
    const period = await Api.request(
      `/companies/${uuid}/billing_account/operations/years`
    )
    dispatch(actions.fetchAvailablePeriodSuccess(period))
  } catch (error) {
    dispatch(
      actions.fetchAvailablePeriodFailure(
        'Проверьте ваше интернет соединение и попробуйте обновить страницу.'
      )
    )
  }
}

export const clearOptions = () => async (dispatch) => {
  dispatch(actions.clearTransactionsData())
}
