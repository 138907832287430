import React from 'react'
import { connect } from 'react-redux'
import { Wrapper } from 'sputnik-ui'
import { Typography } from '@material-ui/core'
import styled from 'styled-components'
import DevicesTable from '../DevicesTable'
import DevicesList from 'features/Devices/DevicesList'

const StyledWrapper = styled(Wrapper)`
  background: ${(p) => p.theme.palette.white['500']};
  padding: 0;
  overflow: hidden;
  flex-direction: column;
`

const Devices = ({ pendingDevices }) => {
  return (
    <>
      <Typography variant="h5" style={{ marginBottom: '1rem' }}>
        {pendingDevices || 0} устройств ожидают отправки.
      </Typography>

      {/* <StyledWrapper data-aos="zoom-out" style={{}}>
        <DevicesTable />
      </StyledWrapper> */}
      <DevicesList />
    </>
  )
}

const mapStateToProps = ({ companies: { pendingDevices } }) => ({
  pendingDevices,
})

export default connect(mapStateToProps)(Devices)
