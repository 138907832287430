//@flow
//$FlowFixMe
import React from 'react'
import { connect } from 'react-redux'
import { Button } from 'sputnik-ui'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  TextField,
} from '@material-ui/core'

import { Formik } from 'formik'
import * as Yup from 'yup'

const Transition = props => <Slide direction="up" {...props} />

const validationSchema = Yup.object().shape({
  intercom: Yup.string()
    .typeError('должен быть строкой')
    .required('введите номер панели')
    .test(
      'len',
      'должен состоять из 10-13 цифр',
      val =>
        val &&
        (val.toString().length === 10 ||
          val.toString().length === 12 ||
          val.toString().length === 13),
    ),
})

const SearchIntercomModal = ({
  open,
  handleClose,
  dispatch,
  handleIntercomSearch,
}: Props) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      PaperProps={{ style: styles.dialogWindow }}
      onExit={() => {}}
    >
      <DialogTitle>Поиск панели</DialogTitle>
      <Formik
        validationSchema={validationSchema}
        initialValues={{ intercom: '' }}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            handleIntercomSearch(values)
            handleClose()
          } catch (error) {
            setSubmitting(false)
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent style={{ overflow: 'visible', paddingTop: 0 }}>
              <TextField
                name="intercom"
                label="Номер панели"
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.intercom && !!errors.intercom}
                helperText={touched.intercom && errors.intercom}
                value={values.intercom}
                autoFocus
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClose}
                secondary
                rounded
                size="small"
                tabIndex={-1}
              >
                Закрыть
              </Button>
              <Button
                rounded
                primary
                size="small"
                type="submit"
                disabled={isSubmitting}
              >
                искать
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  )
}

type Props = {
  open: boolean,
  handleClose: Function,
  dispatch: Function,
  handleIntercomSearch: Function,
}

const styles = {
  dialogWindow: { overflow: 'visible', minWidth: 418 },
}

export default connect()(SearchIntercomModal)
