import * as types from './types'

export const fetchTracksRequest = () => ({
  type: types.FETCH_TRACKS_REQUEST,
})
export const fetchTracksSuccess = tracks => ({
  type: types.FETCH_TRACKS_SUCCESS,
  payload: {
    tracks,
  },
})
export const fetchTracksFailure = error => ({
  type: types.FETCH_TRACKS_FAILURE,
  payload: {
    error,
  },
})

export const addTrackRequest = () => ({
  type: types.ADD_TRACK_REQUEST,
})
export const addTrackSuccess = () => ({
  type: types.ADD_TRACK_SUCCESS,
})
export const addTrackFailure = error => ({
  type: types.ADD_TRACK_FAILURE,
  payload: {
    error,
  },
})

export const removeTrackRequest = () => ({
  type: types.ADD_TRACK_REQUEST,
})
export const removeTrackSuccess = () => ({
  type: types.ADD_TRACK_SUCCESS,
})
export const removeTrackFailure = error => ({
  type: types.ADD_TRACK_FAILURE,
  payload: {
    error,
  },
})
