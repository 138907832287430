export const FETCH_TRACKS_REQUEST = 'tracks/FETCH_TRACKS_REQUEST'
export const FETCH_TRACKS_SUCCESS = 'tracks/FETCH_TRACKS_SUCCESS'
export const FETCH_TRACKS_FAILURE = 'tracks/FETCH_TRACKS_FAILURE'

export const ADD_TRACK_REQUEST = 'tracks/ADD_TRACK_REQUEST'
export const ADD_TRACK_SUCCESS = 'tracks/ADD_TRACK_SUCCESS'
export const ADD_TRACK_FAILURE = 'tracks/ADD_TRACK_FAILURE'

export const REMOVE_TRACK_REQUEST = 'tracks/REMOVE_TRACK_REQUEST'
export const REMOVE_TRACK_SUCCESS = 'tracks/REMOVE_TRACK_SUCCESS'
export const REMOVE_TRACK_FAILURE = 'tracks/REMOVE_TRACK_FAILURE'
