//@flow
import * as types from './types'
import { CLEAR_MESSAGES } from '../ui/types'

type State = {
  +active: any[],
  +inactive: any[],
  +error: ?string,
  +msg: ?string,
}

type Action = {
  type: string,
  payload: Object,
}

const initialState: State = {
  active: [],
  inactive: [],
  error: null,
  msg: null,
}

export default function services(
  state: State = initialState,
  { type, payload }: Action
) {
  switch (type) {
    case types.FETCH_SERVICES_SUCCESS:
      const { active, inactive } = payload
      return { ...state, active, inactive }
    case types.FETCH_SERVICES_FAILURE:
      return { ...state, error: payload.error }
    case types.ACTIVATE_SERVICE_SUCCESS:
      return { ...state, msg: payload.msg }
    case types.ACTIVATE_SERVICE_FAILURE:
      return { ...state, error: payload.error }
    case types.DEACTIVATE_SERVICE_SUCCESS:
      return { ...state, msg: payload.msg }
    case types.DEACTIVATE_SERVICE_FAILURE:
      return { ...state, error: payload.error }
    case CLEAR_MESSAGES:
      return { ...state, msg: null, error: null }
    default:
      return state
  }
}
