import React from 'react'
import { FormControlLabel } from '@material-ui/core'
import { useFormik } from 'formik'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import {
  FormModal,
  FormModalTitle,
  FormModalContent,
  FormModalActions,
  Button,
  Radio,
  Typography,
} from 'sputnik-ui'
import { connectCompanyToService } from 'store/companies/operations'
import { getCompanyService } from 'store/companies/operations'

export const DecideServiceModal = ({ open, handleClose: onClose, activeService }) => {
  const dispatch = useDispatch()
  const companyUuid = useSelector(({ companies }) => companies.about?.uuid)
  const isServicesPending = useSelector(({ companies }) => companies.services?.pending)
  const servicesCatalog = useSelector(({ companies }) => companies.servicesCatalog)

  const initialValues = {
    activeService: activeService || { service_config_identifier: '' },
  }

  const handleClose = () => {
    onClose()
  }
  const handleSubmit = () => {
    formik.handleSubmit()
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    onSubmit: async (val) => {
      await dispatch(
        connectCompanyToService({
          companyUuid: companyUuid,
          service: val.activeService.service_config_identifier,
        })
      )
      await dispatch(getCompanyService(companyUuid))
      handleClose()
    },
  })

  const handleChange = (nextValue) => {
    formik.setValues({
      activeService: { service_config_identifier: nextValue },
    })
  }

  const isDisabledSubmit =
    !(companyUuid && formik.values.activeService.service_config_identifier) ||
    isServicesPending ||
    !!!servicesCatalog?.length

  return (
    <FormModal open={open} onClose={handleClose}>
      <FormModalTitle>
        <Typography>Новая услуга</Typography>
      </FormModalTitle>
      <FormModalContent>
        {Boolean(servicesCatalog?.length) ? (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '0.3rem' }}>
            {servicesCatalog.map((service) => {
              return (
                <FormControlLabel
                  label={
                    <Typography style={{ textAlign: 'left' }}>Тариф «{service.title}»</Typography>
                  }
                  onChange={() => handleChange(service.identifier)}
                  control={
                    <Radio
                      color="blue"
                      checked={
                        formik.values.activeService?.service_config_identifier ===
                        service.identifier
                      }
                    />
                  }
                />
              )
            })}
          </div>
        ) : (
          'Response Error'
        )}
      </FormModalContent>
      <FormModalActions>
        <Button variant="v2" primary onClick={handleSubmit} disabled={isDisabledSubmit} fullWidth>
          Подтвердить
        </Button>
        <Button variant="v2" tertiary onClick={handleClose}>
          Отменить
        </Button>
      </FormModalActions>
    </FormModal>
  )
}
