export default [
  {
    id: 'serial_number',
    numeric: false,
    disablePadding: false,
    label: 'устройство',
  },
  {
    id: 'services_count',
    numeric: false,
    disablePadding: false,
    label: 'подключенных услуг',
  },
  {
    id: 'is_online',
    numeric: false,
    disablePadding: false,
    label: 'статус устройства',
  },
  {
    id: 'installed',
    numeric: false,
    disablePadding: false,
    label: 'статус установки',
  },
]
