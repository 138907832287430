import * as types from './types'

export const fetchServicesRequest = () => ({
  type: types.FETCH_SERVICES_REQUEST,
})
export const fetchServicesSuccess = ({ active, inactive }) => ({
  type: types.FETCH_SERVICES_SUCCESS,
  payload: {
    active,
    inactive,
  },
})
export const fetchServicesFailure = error => ({
  type: types.FETCH_SERVICES_FAILURE,
  payload: {
    error,
  },
})

export const activateServiceRequest = () => ({
  type: types.ACTIVATE_SERVICE_REQUEST,
})
export const activateServiceSuccess = msg => ({
  type: types.ACTIVATE_SERVICE_SUCCESS,
  payload: {
    msg,
  },
})
export const activateServiceFailure = error => ({
  type: types.ACTIVATE_SERVICE_FAILURE,
  payload: {
    error,
  },
})

export const deactivateServiceRequest = () => ({
  type: types.DEACTIVATE_SERVICE_REQUEST,
})
export const deactivateServiceSuccess = msg => ({
  type: types.DEACTIVATE_SERVICE_SUCCESS,
  payload: {
    msg,
  },
})
export const deactivateServiceFailure = error => ({
  type: types.DEACTIVATE_SERVICE_FAILURE,
  payload: {
    error,
  },
})
