import camelCase from 'lodash/camelCase'
import { combineReducers } from 'redux'
import devices from 'features/Devices/devicesSlice'
import localities from 'features/Devices/localitiesSlice'
import servicePartners from 'features/Devices/servicePartnersSlice'

const reducers = { devices, localities, servicePartners }

const req = require.context('.', true, /\.\/.+\/reducers\.js$/)

req.keys().forEach((key) => {
  const storeName = camelCase(key.replace(/\.\/(.+)\/.+$/, '$1'))
  reducers[storeName] = req(key).default
})

export default combineReducers(reducers)
