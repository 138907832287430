import Api from 'utils/api'

import * as actions from './actions'

export const fetchDevices = ({ q }) => async dispatch => {
  dispatch(actions.fetchDevicesRequest())
  try {
    const { devices, devices_count } = await Api.request(
      `/devices/search_by_serial?q=${q}`,
    )
    dispatch(actions.fetchDevicesSuccess(devices, devices_count))

    return Promise.resolve(devices)
  } catch (error) {
    dispatch(actions.fetchDevicesFailure('Ошибка при получении устройств'))
    return Promise.reject('Ошибка при получении устройств')
  }
}
